function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + "年前";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + "個月前";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + "日前";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + "小時前";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + "分鐘前";
    }
    return Math.floor(seconds) + " 秒前";
}

function toDateString(date) {
  var dateString =
  date.getUTCFullYear() + "-" +
      ("0" + (date.getUTCMonth()+1)).slice(-2) + "-" +
      ("0" + date.getUTCDate()).slice(-2);
      // ("0" + date.getUTCHours()).slice(-2) + ":" +
      // ("0" + date.getUTCMinutes()).slice(-2) + ":" +
      // ("0" + date.getUTCSeconds()).slice(-2);

  return dateString;
}

export { timeSince, toDateString };