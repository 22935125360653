import React, { useState, useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import BuildIcon from '@mui/icons-material/Build';
import ShareIcon from '@mui/icons-material/Share';
import GitHubIcon from '@mui/icons-material/GitHub';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import AppContext from "../store/app-context";
import { Container, Divider, ListItemButton, Box } from '@mui/material';
import { styled } from "@mui/material/styles";

function Settings() {
  const { colorMode, toggleColorMode } = useContext(AppContext);
  const [darkmode, setDarkmode] = useState(localStorage.getItem("colorMode") === 'dark' ? '開啟' : '關閉');

  return (
    <RootBox>
      <Root
        classes={{ root: classes.root }}
      >
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <BuildIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="架構版本" secondary="更新時間: 15/7/2022" />
          </ListItem>
          <Divider />
          <ListItemButton
            onClick={() => {
              if (colorMode === 'dark') {
                setDarkmode('關閉');
              } else if (colorMode === 'light') {
                setDarkmode('開啟');
              }
              toggleColorMode();
            }}
          >
            <ListItemAvatar>
              <Avatar>
                <DarkModeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="黑夜模式" secondary={darkmode} />
          </ListItemButton>
          <Divider />
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <ShareIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="複製應用程式鏈結" secondary="經不同媒介分給親友" />
          </ListItem>
          <Divider />
          {/* <ListItem>
            <ListItemAvatar>
              <Avatar>
                <GitHubIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="原始碼" secondary="GPL-3.0 License" />
          </ListItem>
          <Divider /> */}
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <DataUsageIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="數據資料來源" secondary="資料一站通、消費者委員會" />
          </ListItem>
        </List>
      </Root>
    </RootBox>
  );
}

const PREFIX = "settings";

const classes = {
  root: `${PREFIX}-root`,
  selected: `${PREFIX}-selected`,
};

const Root = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    // width: '100vh',
    height: '100vh',
    background:
      theme.palette.mode === "dark"
        ? theme.palette.background.primary
        : "#fff",
    color:
      theme.palette.mode === "dark"
        ? "#fff"
        : theme.palette.text.primary,
    divider:
      theme.palette.mode === "dark"
        ? "#fff"
        : theme.palette.text.primary,
    [`& .MuiBottomNavigationAction-root`]: {
      width: "40%",
      minWidth: "auto",
    },
    [`& .MuiBottomNavigationAction-label`]: {
      fontSize: "0.875rem",
    },
    [`& .Mui-selected.${classes.selected}`]: {
      color:
        theme.palette.mode === "dark"
          ? theme.palette.primary.main
          : theme.palette.text.primary,
    },
  },
}));

const RootBox = styled(Box)(({ theme }) => ({
  background: theme.palette.mode === 'dark'
    ? '#1A2027'
    : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/information_bg_2.jpg')`,
  variant: 'outlined',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.primary,
}));

export default Settings;