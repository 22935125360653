import { useRef, useState } from "react";
// Mui Component
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import PersonIcon from '@mui/icons-material/Person';
import React from "react"
import { useAuthDispatch, useAuthState } from "../store/app-context";
import { loginUser } from "../store/action";
// React Router
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

function LoginPage(props) {
    const navigate = useNavigate();
    const usernameInputRef = useRef();
    const passwordInputRef = useRef();
    const [values, setValues] = React.useState({
        username: '',
        password: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const dispatch = useAuthDispatch();
    const { loading, errorMessage } = useAuthState();

    const handleLogin = async(event) => {
        console.log('submit login form')
        event.preventDefault();

        setIsLoading(true);
        const username = usernameInputRef.current.value;
        const password = passwordInputRef.current.value;

        let payload = {username, password}
        setValues({username: '', password: ''});
        try {
            let response = await loginUser(dispatch, payload);
            // console.log('response: ' + response)
            setIsLoading(false);
            if (response === undefined || response.resultCode === '1') {

            } else {
                props.handleClose();
                navigate(`/management/content/add`);
            }
        } catch (error) {
            console.log(error);
        }

        const contentData = {
            username: username,
            password: password,
        };

        console.log(contentData);
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
        >
            <Container>
            <Box sx={{ mt: 10, mb: 10, mx: {md: 20} }} >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {
                    errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null
                }
                <Typography variant="h6" gutterBottom component="div">
                    登入
                </Typography>
                <form onSubmit={handleLogin}>
                    <div>
                        <FormControl sx={{ m: 1, width: '40ch' }} variant="filled">
                            <InputLabel htmlFor="filled-adornment-username">用戶名稱</InputLabel>
                            <FilledInput
                                id="filled-adornment-username"
                                type="text"
                                value={values.username}
                                inputRef={usernameInputRef}
                                onChange={handleChange('username')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                        >
                                            <PersonIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, width: '40ch' }} variant="filled">
                            <InputLabel htmlFor="filled-adornment-password">密碼</InputLabel>
                            <FilledInput
                                id="filled-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                inputRef={passwordInputRef}
                                onChange={handleChange('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, width: '40ch' }} variant="filled">
                            <Button type="submit" variant="contained">登入</Button>
                        </FormControl>
                    </div>
                </form>
            </Box>
            </Container>
        </Dialog>
    )
}

export default LoginPage;