import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress  from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import EditCarParkForm from './EditCarParkForm';
import Swal from 'sweetalert2';
import { API_URL } from '../../environment';

// React Router
import { useNavigate } from "react-router-dom";

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(id, carparkId, name, address, district, phone, latitude, longitude, image) {
    return { id, carparkId, name, district, address, phone, latitude, longitude, image };
}

const rows = [];

function CarParkTable() {
    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loadCarpark, setCarpark] = useState([]);
    const [carparkDetail, setCarparkDetail] = useState([]);

    useEffect(() => {
        const urls = [
            API_URL + `/api/carpark/edit`
        ]
        try {
            let carparks;
            const getCarpark = async () => {
                [carparks] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);
                setCarpark(carparks);

                for (var carpark of carparks) {
                    let id = carpark['id']
                    let carparkId = carpark['carparkId'];
                    let name = carpark['name'];
                    let address = carpark['address'];
                    let district = carpark['district'];
                    let phone = carpark['contactNo'];
                    let latitude = carpark['latitude'];
                    let longitude = carpark['longitude'];
                    let image = carpark['carpark_photo'];

                    rows.push(createData(id, carparkId, name, address, district, phone, latitude, longitude, image));
                }
            }
            getCarpark();

        } catch (error) {
            console.log(error);
        }

    }, []);

    if (isLoading) {
        return (
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClose = () => {
        setOpenEditDialog(false);
    };

    const handleOpen = (row) => {
        setCarparkDetail(row);
        setOpenEditDialog(true);
    }

    const updateCarParkHandler = (contentData) => {
        console.log('updateCarParkHandler...');

        const d = new FormData();
        d.append('image', contentData.carparkPhoto);
        d.append('title', 'carpark');

        fetch(
            API_URL + "/api/carpark/edit",
            {
                method: "POST",
                body: JSON.stringify(contentData),
                headers: {
                    "Content-Type": "application/json",
                },
            }
        ).then((res) => {
            handleClose();
            if (res.status == '201') {
                Swal.fire({
                    title: '完成!',
                    text: '已加入',
                    icon: 'success',
                    confirmButtonText: '關閉',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(0)
                    }
                })
            } else {
                Swal.fire({
                    title: '失敗!',
                    text: '資料錯誤',
                    icon: 'info',
                    confirmButtonText: '關閉'
                })
            }

        });
    }

    const EditDialog = (props) => {
        return (
            <Dialog
                open={openEditDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth={true}
            >
                <Container>
                    <Box sx={{ mt: 5, mb: 5, mx: 5 }} >
                        <EditCarParkForm carparkDetail={props.carparkDetail} editCarPark={updateCarParkHandler} />
                    </Box>
                </Container>
            </Dialog>
        )
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>編號</StyledTableCell>
                        <StyledTableCell align="left">名稱</StyledTableCell>
                        <StyledTableCell align="left">地區</StyledTableCell>
                        <StyledTableCell align="left">地址</StyledTableCell>
                        <StyledTableCell align="left">電話</StyledTableCell>
                        <StyledTableCell align="left">經緯度</StyledTableCell>
                        <StyledTableCell align="left">圖片</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row) => (
                        <StyledTableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            onClick={() => {handleOpen(row)}}
                        >
                            <TableCell component="th" scope="row">
                                {row.carparkId}
                            </TableCell>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">{row.district}</TableCell>
                            <TableCell align="left">{row.address}</TableCell>
                            <TableCell align="left">{row.phone}</TableCell>
                            <TableCell align="left">{row.latitude},{row.longitude}</TableCell>
                            <TableCell align="left">{row.image}</TableCell>
                        </StyledTableRow>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={7}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
            <EditDialog carparkDetail={carparkDetail} />
        </TableContainer>
        
    );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default CarParkTable;