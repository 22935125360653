import { createContext, useCallback, useReducer, useState, useContext } from 'react';
import { produce } from 'immer';
import { dark } from '@mui/material/styles/createPalette';
import { AuthReducer, initialState } from './reducer';

const AppContext = createContext({
    favorites: [],
    totalFavorites: 0,
    addFavorite: (favoriteMeetup) => { },
    removeFavorite: (meetupId) => { },
    itemIsFavorite: (meetupId) => { },
});

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

export function useAuthState() {
    const context = useContext(AuthStateContext);
    if (context === undefined) {
        throw new Error("useAuthState must be used within a authProvider");
    }

    return context;
}

export function useAuthDispatch() {
    const context = useContext(AuthDispatchContext);
    if (context === undefined) {
        throw new Error("useAuthDispatch  must be used within a authProvider");
    }

    return context;
}

export const AuthProvider = ({ children }) => {
    const [user, dispatch] = useReducer(AuthReducer, initialState);

    return (
        <AuthStateContext.Provider value={user}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    )
}

const isColorMode = (input) => {
    return input === "dark" || input === "light";
};

export function AppContextProvider(props) {
    const getInitialState = () => {
        const devicePreferColorScheme =
            localStorage.getItem("colorMode") ||
            (navigator.userAgent === "prerendering" && "dark") || // set default color theme in prerendering to "dark"
            (window.matchMedia &&
                window.matchMedia("(prefers-color-scheme: light)").matches
                ? "dark"
                : "light");
        return {
            colorMode: isColorMode(devicePreferColorScheme)
                ? devicePreferColorScheme
                : "dark",
        }
    }

    const [userFavorites, setUserFavorites] = useState([]);
    const [state, setStateRaw] = useState(getInitialState);

    function addFavoriteHandler(favoriteMeetup) {
        setUserFavorites((prevUserFavorites) => {
            return prevUserFavorites.concat(favoriteMeetup);
        });
    }

    function removeFavoriteHandler(meetupId) {
        setUserFavorites((prevUserFavorites) => {
            return prevUserFavorites.filter((meetup) => meetup.id !== meetupId);
        });
    }

    function itemIsFavoriteHandler(meetupId) {
        return userFavorites.some((meetup) => meetup.id === meetupId);
    }

    const toggleColorMode = useCallback(() => {
        console.log('clicked toggle color mode...');
        setStateRaw(
            produce(state => {
                const prevColorMode = state.colorMode;
                const colorMode = prevColorMode === "dark" ? "light" : "dark";
                localStorage.setItem("colorMode", colorMode);
                state.colorMode = colorMode;
            })
        )
    })

    const contextValue = {
        ...state,
        favorites: userFavorites,
        totalFavorites: userFavorites.length,
        addFavorite: addFavoriteHandler,
        removeFavorite: removeFavoriteHandler,
        itemIsFavorite: itemIsFavoriteHandler,
        toggleColorMode: toggleColorMode,
    };

    return (
        <AppContext.Provider value={contextValue}>
            {props.children}
        </AppContext.Provider >
    );
}

export default AppContext;