import { useState, useEffect, forwardRef, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import classes from "../meetups/NewMeetupForm.module.css";

// Hooks
import { useRef } from "react";

function NewCarParkForm(props) {
    const carparkIdInputRef = useRef();
    const carparkNameInputRef = useRef();
    const imageInputRef = useRef();
    const latitudeInputRef = useRef();
    const longitudeInputRef = useRef();

    function submitHandler(event) {
        event.preventDefault();

        const carparkId = carparkIdInputRef.current.value;
        const carparkName = carparkNameInputRef.current.value;
        const image = document.getElementsByClassName('input-image')[0].files[0];
        // const district = districtInputRef.current.value;
        // const tel = telInputRef.current.value;
        // const address = addressInputRef.current.value;
        const latitude = latitudeInputRef.current.value;
        const longitude = longitudeInputRef.current.value;
        const lastModifiedDate = new Date();

        const contentData = {
            carparkId: carparkId,
            name: carparkName,
            carparkPhoto: image,
            district: '',
            tel: '',
            address: '',
            latitude: latitude,
            longitude: longitude,

            lastModifiedDate: lastModifiedDate
        };

        console.log(contentData);

        props.onAddContent(contentData);
    }

    return (
        <Card sx={{ mt: 1, mb: 5 }} >
            <form className={classes.form} onSubmit={submitHandler}>
                <div className={classes.control}>
                    <label htmlFor="description">停車場編號</label>
                    <TextField required id="carparkId" inputRef={carparkIdInputRef} sx={{width: '100%'}}/>
                </div>
                <div className={classes.control}>
                    <label htmlFor="title">停車場名稱</label>
                    <TextField required id="carparkName" inputRef={carparkNameInputRef} sx={{width: '100%'}}/>
                </div>
                <div className={classes.control}>
                    <label htmlFor="image">圖片</label>
                    <input type="file" className="input-image" required id="image" ref={imageInputRef} />
                </div>
                <div className={classes.control}>
                    <label htmlFor="latitude">緯度</label>
                    <TextField required id="latitude" inputRef={latitudeInputRef} sx={{width: '100%'}}/>
                </div>
                <div className={classes.control}>
                    <label htmlFor="longitude">經度</label>
                    <TextField required id="longitude" inputRef={longitudeInputRef} sx={{width: '100%'}}/>
                </div>

                <div className={classes.actions}>
                    <button>加入</button>
                </div>
            </form>
        </Card>
    )
}

export default NewCarParkForm;