
import { Marker } from '@react-google-maps/api';

const gasStations = [
    {district: "香港", name: "域多利道", tel: "+852 2551 9346", address: "域多利道 555 號 （近碧瑤灣）", latitude: "22.2621291", longtitude: "114.1281923" },
    {district: "香港", name: "薄扶林", tel: "+852 2495 2585", address: "薄扶林道100號", latitude: "22.2749921", longtitude: "114.1217598"},
    {district: "香港", name: "鰂魚涌", tel: "+852 2561 3084 ", address: "鰂魚涌英皇道 979 號 A", latitude: "22.287612", longtitude: "114.1836594"},
    {district: "香港", name: "電氣道", tel: "+852 2570 3034 ", address: "北角電氣道 169-171 號", latitude: "22.2872459", longtitude: "114.2023239"},
]

function GasStationList(gasStations) {
    const openGoogleMap = () => {
        window.open('https://www.google.com/maps?q=%E8%A5%BF%E8%B2%A2%E5%85%AC%E7%9C%BE%E5%81%9C%E8%BB%8A%E5%A0%B4&source=lmns&bih=628&biw=1280&hl=zh-TW&sa=X&ved=2ahUKEwii1pLe3Lr3AhVLFIgKHSeTBU0Q_AUoAXoECAEQAQ');
    }

    return (
        <>  {
                Object.keys(gasStations).forEach(gasStation => {
                    const position = {
                        lat: gasStation.latitude,
                        lng: gasStation.longtitude
                    };
                    <Marker
                        icon={"/shell.png"}
                        position={position}
                        onClick={() => {console.log('clicked' + gasStation.name); openGoogleMap();}}
                    />
                })
            }
        </>
    );
}

export default GasStationList;