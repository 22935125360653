import { useState, useEffect, forwardRef, Fragment } from "react";

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { Form } from 'react-bootstrap';
import {
    CircularProgress, Container, Select, MenuItem, Dialog, makeStyles, Slide,
    Grid, Box, ImageListItem, List, ListItem, ListItemAvatar, Typography, Divider, ListItemText, Avatar, Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";

import "../index.css";
import classesLayout from '../components/layout/Layout.module.css';
import { API_URL } from '../environment';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    stacked: false,
    plugins: {
        title: {
            display: true,
            text: '無鉛汽油價格趨勢',
        },
    },
    scales: {
        y: {
            type: 'linear',
            display: true,
            position: 'left',
        },
    },
};

const oilTax = parseFloat(6.06);
const labels = [];
const priceMap = new Map();
var data = {};
// const data = {
//     labels,
//     datasets: [
//         {
//             label: 'Dataset 1',
//             data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//             borderColor: 'rgb(255, 99, 132)',
//             backgroundColor: 'rgba(255, 99, 132, 0.5)',
//             yAxisID: 'y',
//         },
//         {
//             label: 'Dataset 2',
//             data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//             borderColor: 'rgb(53, 162, 235)',
//             backgroundColor: 'rgba(53, 162, 235, 0.5)',
//             yAxisID: 'y1',
//         },
//     ],
// };

// proxy
// https://stackoverflow.com/questions/65427633/fetch-data-from-local-api

function DrivingInfoPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [loadedTodayGasPrice, setLoadedTodayGasPrice] = useState([]);
    const [loadedPeriodGasPrice, setLoadedPeriodGasPrice] = useState([]);

    const [district, setDistrict] = useState("中西區"); // hardcode chinese name before backend ready
    var today = new Date();
    let fromDate = today.getFullYear() + '-' + (today.getMonth() - 3) + '-' + today.getDate();
    let toDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    useEffect(() => {
        const urls = [
            API_URL + `/api/gasprice/today`,
            API_URL + `/api/gasprice/period?from=` + fromDate + `&to=` + toDate
        ]
        try {
            let todayGasPrice, periodGasPrice;
            const getGasPrice = async () => {
                [todayGasPrice, periodGasPrice] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);
                setLoadedTodayGasPrice(todayGasPrice);
                setLoadedPeriodGasPrice(periodGasPrice);

                for (var i = 0; i < Object.keys(periodGasPrice).length; i++) {
                    var tmpData = [];
                    var company = Object.keys(periodGasPrice)[i];
                    var gasPriceData = periodGasPrice[company];
                    // console.log('company: ' + company + ", gasPriceData: " + gasPriceData);
                    for (var gasPrice of gasPriceData) {
                        tmpData.push(parseFloat(gasPrice.price) + oilTax);
                    }
                    priceMap.set(company, tmpData);
                }

                for (var gasPrice of periodGasPrice.shell) {
                    // console.log("push to lables");
                    labels.push(gasPrice.date.split('T')[0]);
                }

                data = {
                    labels,
                    datasets: [
                        {
                            label: '埃索',
                            data: priceMap.get("esso"),
                            borderColor: '#EC4A37',
                            backgroundColor: '#EC4A37',
                            yAxisID: 'y',
                        },
                        {
                            label: '蜆殼',
                            data: priceMap.get("shell"),
                            borderColor: '#FECB39',
                            backgroundColor: '#FECB39',
                            yAxisID: 'y',
                        },
                        {
                            label: '加德士',
                            data: priceMap.get("caltex"),
                            borderColor: '#00485F',
                            backgroundColor: '#00485F',
                            yAxisID: 'y',
                        },
                        {
                            label: '中石化',
                            data: priceMap.get("sinopec"),
                            borderColor: '#000000',
                            backgroundColor: '#000000',
                            yAxisID: 'y',
                        },
                        {
                            label: '中國石油',
                            data: priceMap.get("petroChina"),
                            borderColor: '#FB972C',
                            backgroundColor: '#FB972C',
                            yAxisID: 'y',
                        }
                    ],
                };

                // console.log('todayGasPrice: ' + JSON.stringify(todayGasPrice));
                // console.log('periodGasPrice: ' + JSON.stringify(periodGasPrice));
            }

            getGasPrice();

        } catch (error) {
            console.log(error);
        }

    }, []);

    if (isLoading) {
        return (
            <Loading className={classes.loading}>
                <div className='loading-center'>
                    <div style={{ textAlign: 'center', marginBottom: '6rem' }}>載入中</div>
                    <div className='loading-center'>
                        <CircularProgress />
                    </div>
                </div>
            </Loading>
        )
    }

    return (
        <Item>
        <Root spacing={2} sx={{mb: 6}} classes={{ root: classes.root }}>
            <Typography variant="h4">無鉛汽油</Typography>
            <Typography variant="subtitle2">*已扣除門市折扣</Typography>
            <Grid container>
                <Grid item xs={12} sm={7} lg={7}
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                >
                    <List sx={{ width: '100%', bgcolor: 'backgroup.paper' }}>
                        <ListItem alignItems="center">
                            <ListItemAvatar>
                                <Avatar src={`/img/shell.png`} sx={{ width: '3em', height: '3em' }} />
                            </ListItemAvatar>
                            <ListItemText sx={{ marginLeft: 3 }}
                                primary="蜆殼"
                            />
                            <ListItemText sx={{ marginLeft: 3 }} style={{ display: 'flex', justifyContent: 'flex-end' }}
                                primary={"$" + (parseFloat(loadedTodayGasPrice.shell) + oilTax).toFixed(2)}
                            />
                        </ListItem>
                        <Divider variant="middle" component="li" />
                        <ListItem alignItems="center">
                            <ListItemAvatar>
                                <Avatar src={`/img/esso.png`} sx={{ width: '3em', height: '3em' }} />
                            </ListItemAvatar>
                            <ListItemText sx={{ marginLeft: 3 }}
                                primary="埃索"
                            />
                            <ListItemText sx={{ marginLeft: 3 }} style={{ display: 'flex', justifyContent: 'flex-end' }}
                                primary={"$" + (parseFloat(loadedTodayGasPrice.esso) + oilTax).toFixed(2)}
                            />
                        </ListItem>
                        <Divider variant="middle" component="li" />
                        <ListItem alignItems="center">
                            <ListItemAvatar>
                                <Avatar src={`/img/caltex.png`} sx={{ width: '3em', height: '3em' }} />
                            </ListItemAvatar>
                            <ListItemText sx={{ marginLeft: 3 }}
                                primary="加德士"
                            />
                            <ListItemText sx={{ marginLeft: 3 }} style={{ display: 'flex', justifyContent: 'flex-end' }}
                                primary={"$" + (parseFloat(loadedTodayGasPrice.caltex) + oilTax).toFixed(2)}
                            />
                        </ListItem>
                        <Divider variant="middle" component="li" />
                        <ListItem alignItems="center">
                            <ListItemAvatar>
                                <Avatar src={`/img/sinopec.png`} sx={{ width: '3em', height: '3em' }} />
                            </ListItemAvatar>
                            <ListItemText sx={{ marginLeft: 3 }}
                                primary="中石化"
                            />
                            <ListItemText sx={{ marginLeft: 3 }} style={{ display: 'flex', justifyContent: 'flex-end' }}
                                primary={"$" + (parseFloat(loadedTodayGasPrice.sinopec) + oilTax).toFixed(2)}
                            />
                        </ListItem>
                        <Divider variant="middle" component="li" />
                        <ListItem alignItems="center">
                            <ListItemAvatar>
                                <Avatar src={`/img/petroChina.png`} sx={{ width: '3em', height: '3em' }} />
                            </ListItemAvatar>
                            <ListItemText sx={{ marginLeft: 3 }}
                                primary="中國石油"
                            />
                            <ListItemText sx={{ marginLeft: 3 }} style={{ display: 'flex', justifyContent: 'flex-end' }}
                                primary={"$" + (parseFloat(loadedTodayGasPrice.petroChina) + oilTax).toFixed(2)}
                            />
                        </ListItem>
                        <Divider variant="middle" component="li" />
                    </List>
                </Grid>
                <Grid item xs={12} sm={5} lg={5}
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                    sx={{ mt: 1}}>
                    <Line options={options} data={data} style={{ border: "solid 1px", height: "300px" }}/>
                </Grid>
            </Grid>
            <Typography variant="subtitle2">*零售牌價扣除門市即時拆扣所得的淨價格</Typography>
        </Root>    
        </Item>
    )
}

const Item = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark' 
        ? '#1A2027' 
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/information_bg_2.jpg')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PREFIX = "carparkInfo";

const classes = {
    root: `${PREFIX}-root`,
    selected: `${PREFIX}-selected`,
    loading: `${PREFIX}-loading`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        height: '100vh',
        backgroundColor: theme.palette.mode === 'dark'
            ? '#1A2027'
            : '#fff',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const Loading = styled(Container)(({ theme }) => ({
    [`&.${classes.loading}`]: {
        height: '90vh',
        backgroundColor: 
            theme.palette.mode === 'dark' 
                ? '#1A2027' 
                : '#fff',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));


export default DrivingInfoPage;