import { Link, useLocation, useHistory } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalParkingIcon from "@mui/icons-material/LocalParking"
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MapIcon from '@mui/icons-material/Map';
import { styled } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MuiLink from '@mui/material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" sx={{ml: 1}}>
            {'Copyright © '}
            <MuiLink color="inherit" href="https://carpark.dllmon99.com/">
                carcarclub 車迷會
            </MuiLink>{' '}
            {new Date().getFullYear()}
            {' All Rights Reserved.'}
        </Typography>
    );
}

function Footer() {
    const location = useLocation();

    return (
        <>
            <Root
                value={location.pathname.replace(/(.*)\/[0-9]*?$/, "$1")}
                showLabels={true}
                classes={{ root: classes.root }}
                sx={{ display: { xs: 'flex', md: 'none' } }}
            >
                <BottomNavigationAction
                    label={"首頁"}
                    component={Link}
                    to={`/home`}
                    // onClick={(e) => handleClick(`/${i18n.language}`, e)}
                    value={`/home`}
                    icon={<HomeIcon />}
                    classes={{
                        root: "footer-actionItem",
                        selected: "footer-selected",
                    }}
                />
                <BottomNavigationAction
                    label={"資訊"}
                    component={Link}
                    to={`/drivingInfo`}
                    // onClick={(e) => handleClick(`/${i18n.language}/board`, e)}
                    value={`/drivingInfo`}
                    icon={<DirectionsCarIcon />}
                    classes={{
                        root: "footer-actionItem",
                        selected: "footer-selected",
                    }}
                />
                <BottomNavigationAction
                    label={"泊車"}
                    component={Link}
                    to={`/car-park-info`}
                    // onClick={(e) =>
                    //     handleClick(
                    //         `/${i18n.language}/route/${selectedRoute.toLowerCase()}`,
                    //         e
                    //     )
                    // }
                    value={`/car-park-info`}
                    icon={<LocalParkingIcon />}
                    style={{ textTransform: "uppercase" }}
                    classes={{
                        root: "footer-actionItem",
                        selected: "footer-selected",
                    }}
                />
                <BottomNavigationAction
                    label={"油站"}
                    component={Link}
                    to={`/map`}
                    // onClick={(e) => handleClick(`/${i18n.language}/search`, e)}
                    value={`/map`}
                    icon={<MapIcon />}
                    classes={{
                        root: "footer-actionItem",
                        selected: "footer-selected",
                    }}
                />
                <BottomNavigationAction
                    label={"設定"}
                    component={Link}
                    to={`/settings`}
                    // rel="nofollow"
                    // onClick={(e) => handleClick(`/${i18n.language}/settings`, e)}
                    value={`/settings`}
                    icon={<SettingsIcon />}
                    classes={{
                        root: "footer-actionItem",
                        selected: "footer-selected",
                    }}
                />
            </Root>

            <Box
                sx={{
                    display: { xs: 'none', md: 'flex' },
                    flexDirection: 'column',
                    // minHeight: '100vh',
                }}
            >
                <CssBaseline />
                <Box
                    component="footer"
                    sx={{
                        py: 3,
                        px: 2,
                        mt: 'auto',
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[200]
                                : theme.palette.background.default,
                    }}
                >
                    <Container>
                        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 1}}>
                            <label htmlFor="icon-button-file">
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                <FacebookIcon />
                                </IconButton>
                            </label>
                            <label htmlFor="icon-button-bold">
                                <IconButton color="primary" aria-label="format bold" component="span" onClick={() => {}}>
                                <InstagramIcon />
                                </IconButton>
                            </label>
                            <label htmlFor="icon-button-itlaic">
                                <IconButton color="primary" aria-label="format italic" component="span" onClick={() => {}}>
                                <WhatsAppIcon />
                                </IconButton>
                            </label>
                            <label htmlFor="icon-button-underlined">
                                <IconButton color="primary" aria-label="format underlined" component="span" onClick={() => {}}>
                                <TelegramIcon />
                                </IconButton>
                            </label>
                        </Stack>
                        <Copyright />
                    </Container>
                </Box>
            </Box>
        </>
    );
}

export default Footer;

const PREFIX = "footer";

const classes = {
  root: `${PREFIX}-root`,
  selected: `${PREFIX}-selected`,
};

const Root = styled(BottomNavigation)(({ theme }) => ({
    [`&.${classes.root}`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.default
                : "#f3c622",
        position: "fixed",
        width: "100%",
        bottom: "0",
        height: "initial",
        [`& .MuiBottomNavigationAction-root`]: {
            width: "40%",
            minWidth: "auto",
        },
        [`& .MuiBottomNavigationAction-label`]: {
            fontSize: "0.875rem",
        },
        [`& .Mui-selected.${classes.selected}`]: {
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.primary.main
                    : theme.palette.text.primary,
        },
    },
}));


