import { useState, useEffect, forwardRef, Fragment } from "react";
// React Router
import { useNavigate, useLocation, useParams } from "react-router-dom";
import MapIcon from '@mui/icons-material/Map';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import { yellow } from '@mui/material/colors'
import { styled } from "@mui/material/styles";
import { API_URL } from '../environment';


function CategoryResultPage(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [result, setResult] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { type } = useParams();
    let url = '';
    let title = '';

    const toContentPage = (redirectUrl) => {
        navigate(redirectUrl);
        navigate(0); // force to reload page
    }

    if (type === 'promotion') {
        title = '泊車優惠';
        url = API_URL + `/api/content/promotion`;
    } else {
        url = API_URL + `/api/content/district/` + location.state.districtId;
    }

    useEffect(() => {
        const urls = [url]
        try {
            let searchingResult;
            const getResult = async () => {
                [searchingResult] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);
                setResult(searchingResult);
            }
            getResult();

        } catch (error) {
            console.log(error);
        }

    }, []);


    if (isLoading) {
        return (
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <RootBox>
            <Root className={classes.root}>
                <Box sx={{ p: 1 }}>
                    <Typography variant="h4"><MapIcon fontSize="large" color="primary" /> {(location.state ? location.state.districtName : title)}</Typography>
                </Box>
                <CustomDivider className={'categoryResult'} />
                <Box>
                    <ContentGrid contentList={result} toContentPage={toContentPage} sx={{ flexGrow: 1 }} />
                </Box>
            </Root>
        </RootBox>
    )
}

function ContentGrid(props) {
    if (props.contentList.length > 0) {
        return (
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 12 }}>
                {props.contentList.map((item) => (
                    <Grid item xs={12} sm={2} md={3} key={item.title}>
                        <Card sx={{ maxWidth: 345 }}>
                            <Link href="" onClick={(e) => { e.preventDefault(); props.toContentPage(item.redirectUrl) }} color="inherit">
                            <CardMedia
                                component="img"
                                alt={item.name}
                                height="140"
                                image={item.image}
                                onClick={(e) => { e.preventDefault(); props.toContentPage(item.redirectUrl) }}
                            />
                            </Link>
                            <CustomDivider className={'cardContent'} />
                            <CardContent sx={{ height: 150 }}>
                                <Link href="" onClick={(e) => { e.preventDefault(); props.toContentPage(item.redirectUrl) }} underline="hover" color="#985b10">
                                    <Typography align="left" gutterBottom variant="subtitle1" component="div">
                                        {item.category === "promotion" ? "泊車優惠" : (item.category === "parkbin" ? "十八區玩樂" : "")}
                                    </Typography>
                                </Link>

                                <Link href="" onClick={(e) => { e.preventDefault(); props.toContentPage(item.redirectUrl) }} underline="hover" color="inherit">
                                    <Typography 
                                        sx={{
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2,
                                        }}
                                        align="left" variant="h6" component="div">
                                        {item.title}
                                    </Typography>
                                </Link>
                                <Typography variant="body2" color="text.secondary">
                                    {item.createdDate}
                                </Typography>
                            </CardContent>
                            {/* <CardActions>
                                <Button size="small">Share</Button>
                                <Button size="small">Learn More</Button>
                            </CardActions> */}
                        </Card>
                    </Grid>
                ))}
            </Grid>
        )
    } else {
        return (
            <Box>
                <Typography variant="h6" color="text.secondary">
                    未有相關資訊
                </Typography>
            </Box>
        )
    }
}

const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.categoryResult`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : theme.palette.primary.main,
        borderBottomWidth: 6,
        marginBottom: 20
    },
    [`&.cardContent`]: {
        background:
            theme.palette.mode === "dark"
                ? yellow['A400']
                : yellow['A400'],
        borderBottomWidth: 5
    },

}));

const districtData = [
    {
        name: '中西區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202207/20220714170933_0_1657789773.jpg'
    },
    {
        name: '灣仔區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202207/20220714125358_0_thumbnail.jpg'
    },
    {
        name: '東區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202207/20220713174630_0_244443882-10158165753262181-7017236995514123273-n.jpg'
    },
    {
        name: '南區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202207/20220713144341_0_cover.jpg'
    },
    {
        name: '油尖旺區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202207/20220712161547_0_006.jpg'
    },
    {
        name: '深水埗區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202207/20220711121009_0_cover.jpg'
    },
    {
        name: '九龍城區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202207/20220706151603_0_p2022070500574-photo-1217173.jpg'
    },
    {
        name: '黃大仙區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202207/20220706134042_0_fotojet-21.jpg'
    },
    {
        name: '觀塘區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202207/20220704152538_3_sruvi4iavdppwpf3jra0.jpg'
    },
    {
        name: '葵青區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202207/20220704162251_0_1.jpg'
    },
    {
        name: '屯門區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202206/20220630181112_0_wjmcmwqbr4dsu24nemze.jpg'
    },
    {
        name: '元朗區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202206/20220629122259_2_1656476579.png'
    },
    {
        name: '北區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202206/20220630145346_0_murray-hotel.jpg'
    },
    {
        name: '大埔區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202112/20220629171547_0_2.jpg'
    },
    {
        name: '沙田區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202205/20220531152932_0_1653982172.jpg'
    },
    {
        name: '西頁區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202206/20220624173502_0_cover.jpg'
    },
    {
        name: '荃灣區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202206/20220613184219_0_new.jpg'
    },
    {
        name: '離島區',
        image: 'https://hk.ulifestyle.com.hk/cms/images/event/480x270/202206/20220617232203_0_go-museum-x-nexxcreate-photo-1.jpg'
    },
]

const PREFIX = "catgoryResult";

const classes = {
    root: `${PREFIX}-root`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        height: '100%',
        backgroundColor: theme.palette.mode === 'dark'
            ? '#1A2027'
            : '#fff',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '90vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('/img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

export default CategoryResultPage;