import { useNavigate } from "react-router-dom";
import MapIcon from '@mui/icons-material/Map';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import { styled } from "@mui/material/styles";

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

function DistrictPage() {
    const navigate = useNavigate();

    const toCategoryResultPage = (e, districtId, districtName) => {
        e.preventDefault(); 
        console.log("id: " + districtId + ", name:" + districtName)
        navigate('/category/', {
            state: {
                districtId: districtId,
                districtName: districtName,
            }
        });
        // navigate(0); // force to reload page
    }

    return (
        <RootBox>
            <Root className={classes.root}>
                <Box sx={{ p: 1 }}>
                    <Typography variant="h4"><MapIcon fontSize="large" color="primary" /> 18區點泊好</Typography>
                </Box>
                <CustomDivider className={'District'} />
                <Box>
                    <DistrictImageList toCategoryResultPage={toCategoryResultPage} />
                </Box>
            </Root>
        </RootBox>
    )
}

function DistrictImageList(props) {
    return (
        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 4, md: 12 }}>
            {districtData.map((item) => (
                <Grid item xs={2} sm={2} md={3} key={item.name}>
                    <Card variant="outlined" sx={{ maxWidth: 345 }}>
                        <Link href="" onClick={(e) => { props.toCategoryResultPage(e, item.id, item.name) }} color="inherit">
                        <CardMedia
                            component="img"
                            alt={item.name}
                            height="150"
                            image={item.image}
                            // onClick={(e) => { e.preventDefault(); props.toCategoryResultPage(item.id, item.name) }}
                        />
                        </Link>
                        <CardContent>
                            <Link href="" onClick={(e) => { props.toCategoryResultPage(e, item.id, item.name) }} underline="hover" color="inherit">
                                <Typography align="left" variant="h6" component="div">
                                    {item.name}
                                </Typography>
                            </Link>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}

const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.District`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : theme.palette.primary.main,
        borderBottomWidth: 6,
        marginBottom: 20
    },

}));

const ImageGalleryList = styled('ul')(({ theme }) => ({
    display: 'grid',
    padding: 0,
    // margin: theme.spacing(0, 4),
    gap: 8,
    [theme.breakpoints.up('xs')]: {
        gridTemplateRows: 'repeat(9, 150px)',
        gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.up('md')]: {
        gridTemplateRows: 'repeat(3, 200px)',
        gridTemplateColumns: 'repeat(4, 1fr)'
    },
}));

const districtData = [
    {
        name: '中西區',
        id: '9',
        image: '/img/district/640px-Hong_Kong_Central_and_Western_District.svg.png'
    },
    {
        name: '灣仔區',
        id: '3',
        image: '/img/district/640px-Hong_Kong_Wan_Chai_District.svg.png'
    },
    {
        name: '東區',
        id: '8',
        image: '/img/district/640px-Hong_Kong_Eastern_District.svg.png'
    },
    {
        name: '南區',
        id: '1',
        image: '/img/district/640px-Hong_Kong_Southern_District.svg.png'
    },
    {
        name: '油尖旺區',
        id: '18',
        image: '/img/district/640px-Hong_Kong_Yau_Tsim_Mong_District.svg.png'
    },
    {
        name: '深水埗區',
        id: '2',
        image: '/img/district/640px-Hong_Kong_Sham_Shui_Po_District.svg.png'
    },
    {
        name: '九龍城區',
        id: '7',
        image: '/img/district/640px-Hong_Kong_Kowloon_City_District.svg.png'
    },
    {
        name: '黃大仙區',
        id: '5',
        image: '/img/district/640px-Hong_Kong_Wong_Tai_Sin_District.svg.png'
    },
    {
        name: '觀塘區',
        id: '6',
        image: '/img/district/640px-Hong_Kong_Kwun_Tong_District.svg.png'
    },
    {
        name: '葵青區',
        id: '11',
        image: '/img/district/640px-Hong_Kong_Kwai_Tsing_District.svg.png'
    },
    {
        name: '屯門區',
        id: '13',
        image: '/img/district/640px-Hong_Kong_Tuen_Mun_District.svg.png'
    },
    {
        name: '元朗區',
        id: '14',
        image: '/img/district/640px-Hong_Kong_Yuen_Long_District.svg.png'
    },
    {
        name: '北區',
        id: '15',
        image: '/img/district/640px-Hong_Kong_North_District.svg.png'
    },
    {
        name: '大埔區',
        id: '16',
        image: '/img/district/640px-Hong_Kong_Tai_Po_District.svg.png'
    },
    {
        name: '沙田區',
        id: '4',
        image: '/img/district/640px-Hong_Kong_Tai_Po_District.svg.png'
    },
    {
        name: '西貢區',
        id: '17',
        image: '/img/district/640px-Hong_Kong_Sai_Kung_District.svg.png'
    },
    {
        name: '荃灣區',
        id: '10',
        image: '/img/district/640px-Hong_Kong_Tsuen_Wan_District.svg.png'
    },
    {
        name: '離島區',
        id: '12',
        image: '/img/district/640px-Hong_Kong_Islands_District.svg.png'
    },
    {
        name: '特別版',
        id: '0',
        image: '/logo192.png'
    },
]

const PREFIX = "district";

const classes = {
    root: `${PREFIX}-root`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        height: '100%',
        backgroundColor: theme.palette.mode === 'dark'
            ? '#1A2027'
            : '#fff',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '90vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

export default DistrictPage;