// import * as React from 'react';
import { React, useState, useEffect, forwardRef, Fragment } from "react";
// React Router
import { useNavigate } from "react-router-dom";
import {
    IconButton, ImageList, ImageListItem, ImageListItemBar, Box, ListItemText,
    Typography, Divider, Avatar, ListItem, ListItemAvatar, Grid, List, Paper, CircularProgress,
    Card, CardMedia, CardContent, CardActions, Button, Stack
} from '@mui/material/';
import InfoIcon from '@mui/icons-material/Info';
import { styled, keyframes } from "@mui/material/styles";
import { borders } from '@mui/system';
import { toDateString } from '../../util/TimeUtil';
import { API_URL } from '../../environment';
import Carousel from 'react-material-ui-carousel'


function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const Banner = () => {
    // dynamically adjust ImageList columns base on screen size
    // https://stackoverflow.com/questions/69383051/dynamically-adjust-imagelist-columns-based-on-screen-size?noredirect=1&lq=1https://stackoverflow.com/questions/69383051/dynamically-adjust-imagelist-columns-based-on-screen-size?noredirect=1&lq=1
    const [isLoading, setIsLoading] = useState(true);
    const [loadParkBin, setParkBin] = useState([]);
    const navigate = useNavigate();

    const toParkBinDetailPage = (parkbinId) => {
        navigate(`/parkbin/` + parkbinId);
    }

    useEffect(() => {
        const urls = [
            API_URL + `/api/parkbin?limit=`
        ]
        try {
            let parkbin;
            const getParkBin = async () => {
                [parkbin] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);

                // convert to image list view data
                if (parkbin.length > 0) {
                    // parkbin[0].rows = 2;
                    // parkbin[0].cols = 2;
                    // parkbin[1].rows = 1;
                    // parkbin[2].rows = 1;
                    // parkbin[0].rows = 2
                    // parkbin[0].cols = 3;
                    // parkbin[3].cols = 3;
                }

                // console.log('res: ' + JSON.stringify(parkbin));
                setParkBin(parkbin);

            }
            getParkBin();


        } catch (error) {
            console.log(error);
        }

    }, []);

    return (
        <BannerImageGalleryList>
            {loadParkBin.map((item) => (
                <ImageListItemScale
                    key={item.title}
                    sx={{
                        // some styles
                        ":hover": {
                            //   boxShadow: 6,
                        },
                    }}
                    onClick={() => toParkBinDetailPage(item.id)}>
                    <img
                        src={`${item.photoBanner}?w=248&fit=crop&auto=format`}
                        srcSet={`${item.photoBanner}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                        style={{ borderRadius: '6px' }}
                    />
                    <ImageListItemBar
                        title={item.title}
                        // subtitle={item.author}
                        actionIcon={
                            <IconButton
                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                aria-label={`info about ${item.title}`}
                            >
                                <InfoIcon />
                            </IconButton>
                        }
                        sx={{ borderRadius: '0 0 6px 6px' }}
                    />
                </ImageListItemScale>
            ))}
        </BannerImageGalleryList>
    )
}

const HotCarparkList = () => {
    const [loadCarpark, setCarpark] = useState([]);
    const navigate = useNavigate();

    const toCarparkDetailPage = (parkbinId) => {
        navigate(`/parkbin/` + parkbinId);
    }

    useEffect(() => {
        const urls = [
            API_URL + `/api/carpark/edit?random=Y&limit=2`
        ]
        try {
            let carpark;
            const getCarpark = async () => {
                [carpark] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                // setIsLoading(false);

                // console.log('res: ' + JSON.stringify(carpark));
                console.log('length: ' + carpark.length);

                var item = [];
                var items = [];

                for (var i = 0; i < carpark.length; i++) {
                    // console.log(carpark[i]);

                    item.push(carpark[i]);
                    // console.log(item);
                    if (i > 0 && i % 3 === 0) {
                        items.push({ items2: item });
                        item = [];
                    }
                }

                setCarpark(items);

            }
            getCarpark();

        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <Carousel
            autoPlay="false"
            animation="slide"
        >
            {
                loadCarpark.map((item, i) => {
                    return (
                        <Grid item
                            key={i}
                            container
                            spacing={0}
                            direction="row"
                            justifyContent="center"
                        >
                            <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 1 }}>
                                <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <CarparkItem key={i + 1} item={item.items2[0]} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <CarparkItem key={i + 2} item={item.items2[1]} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <CarparkItem key={i + 3} item={item.items2[2]} />
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Grid>
                    )
                })
            }
        </Carousel>
    )
}

const CarparkItem = (props) => {
    return (
        <Grid item xs={12} sm={12} md={4} key={props.item['name']}>
            <CardScale sx={{ width: {md: 345}, maxHeight: {xs: 150, sm: 200, md: 300}, position: 'relative' }}>
                <CardMedia
                    component="img"
                    alt={props.item['item']}
                    height="240"
                    image="/img/it-dog.jpeg"
                />
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.54)',
                        color: 'white',
                        padding: '15px',
                    }}
                >
                    <Typography sx={{fontWeight: 'bold'}} variant="h6">{props.item['name']}</Typography>
                    <Typography variant="body2">{props.item['address']}</Typography>
                    {/* <CardActions> */}
                        <Button size="small">前往</Button>
                    {/* </CardActions> */}
                </Box>
                
            </CardScale>
        </Grid>

    )
}


const Standard = () => {
    // dynamically adjust ImageList columns base on screen size
    // https://stackoverflow.com/questions/69383051/dynamically-adjust-imagelist-columns-based-on-screen-size?noredirect=1&lq=1https://stackoverflow.com/questions/69383051/dynamically-adjust-imagelist-columns-based-on-screen-size?noredirect=1&lq=1
    const [isLoading, setIsLoading] = useState(true);
    const [loadParkBin, setParkBin] = useState([]);
    const navigate = useNavigate();

    const toParkBinDetailPage = (parkbinId) => {
        navigate(`/parkbin/` + parkbinId);
    }

    useEffect(() => {
        const urls = [
            API_URL + `/api/parkbin?limit=4`
        ]
        try {
            let parkbin;
            const getParkBin = async () => {
                [parkbin] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);

                // convert to image list view data
                if (parkbin.length > 0) {
                    parkbin[0].cols = 2;
                    parkbin[1].cols = 2;
                    parkbin[2].cols = 2;
                    parkbin[3].cols = 2;
                }

                setParkBin(parkbin);
            }
            getParkBin();

        } catch (error) {
            console.log(error);
        }

    }, []);

    return (
        <ImageGalleryList>
            {loadParkBin.map((item) => (
                <ImageListItemScale key={item.title} cols={item.cols || 1} rows={item.rows || 1} onClick={() => toParkBinDetailPage(item.id)}>
                    <img
                        {...srcset(item.photoBanner, 121, item.rows, item.cols)}
                        alt={item.title}
                        loading="lazy"
                        height={121}
                        style={{ borderRadius: '6px' }}
                    />
                    <ImageListItemBar
                        sx={{
                            background:
                                'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                            textAlign: 'center',
                            borderRadius: '0 0 6px 6px'
                        }}
                        title={item.title}
                        // subtitle={item.author}
                        actionIcon={
                            <IconButton
                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                aria-label={`info about ${item.title}`}
                            >
                                <InfoIcon />
                            </IconButton>
                        }
                    />
                </ImageListItemScale>
            ))}
        </ImageGalleryList>
    )
}

const Discount = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [loadPromotion, setPromotion] = useState([]);
    const navigate = useNavigate();

    const toPromotionDetailPage = (promotionId) => {
        navigate(`/content/promotion/` + promotionId);
    }

    useEffect(() => {
        const urls = [
            API_URL + `/api/promotion/latest`
        ]
        try {
            let promotion;
            const getPromotion = async () => {
                [promotion] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);

                // convert to image list view data
                if (promotion.length > 0) {
                    promotion[0].cols = 2;
                    promotion[1].cols = 2;
                    promotion[2].cols = 2;
                    promotion[3].cols = 2;
                }

                setPromotion(promotion);
            }
            getPromotion();

        } catch (error) {
            console.log(error);
        }

    }, []);


    if (isLoading) {
        return (
            <>
                <div className='loading-center'>
                    <div style={{ textAlign: 'center', marginBottom: '6rem' }}>載入中</div>
                    <div className='loading-center'>
                        <CircularProgress />
                    </div>
                </div>
            </>
        )
    }

    const DiscountList = (props) => {
        if (props.loadPromotion.length > 0) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                        sx={{ mt: 1 }}
                        onClick={() => toPromotionDetailPage(props.loadPromotion[0].id)}>
                        {/* Grid View for Homepage */}
                        <Grid item xs={12} sm={12}>
                            <Card variant="outlined">
                                <CardMedia>
                                    <PromotionImageGalleryList>
                                        <ImageListItem key={props.loadPromotion[0].title} cols={props.loadPromotion[0].cols || 1} rows={2} elevation={1}>
                                            <img
                                                {...srcset(loadPromotion[0].image, 121, 4, 4)}
                                                src={props.loadPromotion[0].image}
                                                alt={props.loadPromotion[0].title}
                                                loading="lazy"
                                                position="absolute"
                                            />
                                        </ImageListItem>
                                    </PromotionImageGalleryList>
                                </CardMedia>
                                <CardContent sx={{ mt: -2 }}>
                                    <Typography sx={{ typography: { sm: 'subtitle1', xs: 'subtitle1', md: 'h6' } }}>{props.loadPromotion[0].title}</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {toDateString(new Date(props.loadPromotion[0].createdDate))}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                    >
                        <List sx={{ width: '100%', bgcolor: 'backgroup.paper' }}>
                            <ListItem alignItems="center" onClick={() => toPromotionDetailPage(props.loadPromotion[1].id)}>
                                <ListItemAvatar>
                                    <Avatar src={props.loadPromotion[1].image} sx={{ width: '5em', height: '5em' }} />
                                </ListItemAvatar>
                                <ListItemText sx={{ marginLeft: 3 }}
                                    primary={props.loadPromotion[1].title}
                                    secondary={
                                        <Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body"
                                                color="text.primary"
                                            >
                                                {props.loadPromotion[1].createDate}
                                            </Typography>
                                            <Typography variant="body" color="text.secondary">
                                                {toDateString(new Date(props.loadPromotion[1].createdDate))}
                                            </Typography>
                                        </Fragment>
                                    }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" sx={{ marginLeft: '25%'}}/>
                            <ListItem alignItems="center" onClick={() => toPromotionDetailPage(props.loadPromotion[2].id)}>
                                <ListItemAvatar>
                                    <Avatar src={props.loadPromotion[2].image} sx={{ width: '5em', height: '5em' }} />
                                </ListItemAvatar>
                                <ListItemText sx={{ marginLeft: 3 }}
                                    primary={props.loadPromotion[2].title}
                                    secondary={
                                        <Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body"
                                                color="text.primary"
                                            >
                                                {props.loadPromotion[2].createDate}
                                            </Typography>
                                            <Typography variant="body" color="text.secondary">
                                                {toDateString(new Date(props.loadPromotion[2].createdDate))}
                                            </Typography>
                                        </Fragment>
                                    }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" sx={{ marginLeft: '25%'}}/>
                            <ListItem alignItems="center" onClick={() => toPromotionDetailPage(props.loadPromotion[3].id)}>
                                <ListItemAvatar>
                                    <Avatar src={props.loadPromotion[3].image} sx={{ width: '5em', height: '5em' }} />
                                </ListItemAvatar>
                                <ListItemText sx={{ marginLeft: 3 }}
                                    primary={props.loadPromotion[3].title}
                                    secondary={
                                        <Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body"
                                                color="text.primary"
                                            >
                                                {props.loadPromotion[3].createDate}
                                            </Typography>
                                            <Typography variant="body" color="text.secondary">
                                                {toDateString(new Date(props.loadPromotion[3].createdDate))}
                                            </Typography>
                                        </Fragment>
                                    }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" sx={{ marginLeft: '25%'}}/>
                        </List>
                    </Grid>
                </Grid>
            );
        } else {
            <Typography sx={{ typography: { sm: 'h5', xs: 'h5', md: 'h4' } }}>沒有數據</Typography>
        }
    }

    return (
        <DiscountList loadPromotion={loadPromotion} />
    )
}

const ParkingDiscount = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [loadPromotion, setPromotion] = useState([]);
    const navigate = useNavigate();

    const toPromotionDetailPage = (promotionId) => {
        navigate(`/content/promotion/` + promotionId);
        navigate(0); // force to reload page
    }

    useEffect(() => {
        const urls = [
            API_URL + `/api/promotion/latest`
        ]
        try {
            let promotion;
            const getPromotion = async () => {
                [promotion] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);

                // convert to image list view data
                if (promotion.length > 0) {
                    promotion[1].cols = 2;
                    promotion[2].cols = 2;
                    promotion[3].cols = 2;
                }

                setPromotion(promotion);
            }
            getPromotion();

        } catch (error) {
            console.log(error);
        }

    }, []);


    if (isLoading) {
        return (
            <>
                <div className='loading-center'>
                    <div style={{ textAlign: 'center', marginBottom: '6rem' }}>載入中</div>
                    <div className='loading-center'>
                        <CircularProgress />
                    </div>
                </div>
            </>
        )
    }

    return (
        <Box sx={{ m: 1 }}>
            <Typography variant="h6">泊車優惠</Typography>
            <GalleryListDivider className={`ParkingDiscount`} variant="middle" />
            <Grid container spacing={2}>
                <Grid item xs={12}
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                >
                    <List sx={{ width: '100%', bgcolor: 'backgroup.paper' }}>
                        {loadPromotion.map((item) => (
                            <ListItem key={item.title} alignItems="center" onClick={() => toPromotionDetailPage(item.id)}>
                                <ListItemAvatar>
                                    <Avatar src={item.image} sx={{ width: '4em', height: '4em' }} />
                                </ListItemAvatar>
                                <ListItemText sx={{ marginLeft: 3 }}
                                    primary={item.title}
                                    secondary={
                                        <Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body"
                                                color="text.primary"
                                            >
                                                {toDateString(new Date(item.createdDate))}
                                            </Typography>
                                        </Fragment>
                                    }
                                />
                            </ListItem>

                        ))}
                    </List>
                </Grid>
            </Grid>
        </Box>
    )
}

const ParkBin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [loadParkBin, setParkBin] = useState([]);
    const navigate = useNavigate();

    const toParkBinDetailPage = (parkbinId) => {
        navigate(`/parkbin/` + parkbinId);
        navigate(0); // force to reload page
    }

    useEffect(() => {
        const urls = [
            API_URL + `/api/parkbin?limit=4`
        ]
        try {
            let parkbin;
            const getParkbin = async () => {
                [parkbin] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);
                setParkBin(parkbin);
            }
            getParkbin();

        } catch (error) {
            console.log(error);
        }

    }, []);


    if (isLoading) {
        return (
            <>
                <div className='loading-center'>
                    <div style={{ textAlign: 'center', marginBottom: '6rem' }}>載入中</div>
                    <div className='loading-center'>
                        <CircularProgress />
                    </div>
                </div>
            </>
        )
    }

    return (
        <Box sx={{ m: 1 }}>
            <Typography variant="h6">出街泊邊好？</Typography>
            <GalleryListDivider className={`ParkBin`} variant="middle" />
            <Grid container spacing={2}>
                <Grid item xs={12}
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                >
                    <List sx={{ width: '100%', bgcolor: 'backgroup.paper' }}>
                        {loadParkBin.map((item) => (
                            <ListItem key={item.title} alignItems="center" onClick={() => toParkBinDetailPage(item.id)}>
                                <ListItemAvatar>
                                    <Avatar src={item.photoBanner} sx={{ width: '4em', height: '4em' }} />
                                </ListItemAvatar>
                                <ListItemText sx={{ marginLeft: 3 }}
                                    primary={item.title}
                                    secondary={
                                        <Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body"
                                                color="text.primary"
                                            >
                                                {`待定`}
                                            </Typography>
                                        </Fragment>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Box>
    )
}

const ImageListItemScale = styled(ImageListItem)(({ theme }) => ({
    "&:hover": {
        transition: "transform 0.15s ease-in-out",
        transform: "scale3d(1.05, 1.05, 1)"
    },
}));

const CardScale = styled(Card)(({ theme }) => ({
    "&:hover": {
        transition: "transform 0.15s ease-in-out",
        transform: "scale3d(1.05, 1.05, 1)"
    },
}));

const BannerImageGalleryList = styled('ul')(({ theme }) => ({
    display: 'grid',
    padding: 0,
    // margin: theme.spacing(0, 4),
    gap: 15,
    [theme.breakpoints.up('xs')]: {
        gridTemplateRows: 'repeat(6, 10rem)',
        gridTemplateColumns: 'repeat(1, 1fr)'
    },
    [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(3, 200px)'
    },
    [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: 'repeat(2, 15rem)'
    },
    // [theme.breakpoints.up('lg')]: {
    //     gridTemplateColumns: 'repeat(2, 1fr)'
    // },
    // animation: blinkmap !== undefined && pricechangemode === 'blink' ? (blinkmap.get("gbpusd") ? (data.nominal > data.prevClose) ? 
    //         `${theme.palette.mode === 'dark' ? blinkUpDark : blinkUp} 1s linear infinite` 
    //         : `${theme.palette.mode === 'dark' ? blinkDownDark : blinkDown} 1s linear infinite` : null) : null,
}));

const GalleryListDivider = styled(Divider)(({ theme }) => ({
    // [`&.MuiDivider-root`]: {
    //     background:
    //         theme.palette.mode === "dark"
    //             ? theme.palette.primary.main
    //             : theme.palette.action.active,
    //     borderBottomWidth: 2, 
    // },
    [`&.ParkingDiscount`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : theme.palette.primary.main,
        borderBottomWidth: 4,
    },
    [`&.ParkBin`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : "#F38181",
        borderBottomWidth: 4,
    },
    [`&.Event`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : "#EAFFD0",
        borderBottomWidth: 4,
    },

}));

const PromotionImageGalleryList = styled('ul')(({ theme }) => ({
    display: 'grid',
    padding: 0,
    // margin: theme.spacing(0, 4),
    gap: 8,
    [theme.breakpoints.up('xs')]: {
        gridTemplateRows: 'repeat(2, 125px)'
    },
    [theme.breakpoints.up('sm')]: {
        gridTemplateRows: 'repeat(2, 125px)'
    },
    [theme.breakpoints.up('md')]: {
        gridTemplateRows: 'repeat(2, 150px)'
    },
    [`& .MuiImageListItem-img`]: {
        objectPosition: 'top'
    },
}));

const ImageGalleryList = styled('ul')(({ theme }) => ({
    display: 'grid',
    padding: 0,
    // margin: theme.spacing(0, 4),
    gap: 15,
    [theme.breakpoints.up('xs')]: {
        gridTemplateRows: 'repeat(4, 200px)'
    },
    [theme.breakpoints.up('sm')]: {
        gridTemplateRows: 'repeat(4, 200px)'
    },
    [theme.breakpoints.up('md')]: {
        gridTemplateRows: 'repeat(2, 300px)',
        gridTemplateColumns: 'repeat(4, 1fr)'
    },
    // [theme.breakpoints.up('lg')]: {
    //     gridTemplateColumns: 'repeat(2, 1fr)'
    // },
}));


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const CarparkCardContent = styled(CardContent)(({ theme }) => ({
    overlay: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        color: 'black',
        backgroundColor: 'white'
     }
}));

export default { Banner, HotCarparkList, Standard, Discount, ParkingDiscount, ParkBin };
