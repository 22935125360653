import { useContext, useState } from "react";

import FavoritesContext from "../../store/favorites-context";
// import Card from "../ui/Card";
import classes from './CarParkInfoItem.module.css';
import { Stack, Container, Row } from 'react-bootstrap';
import { Card, CardMedia, CardContent, CardActions, Typography, Box, Grid, Button, Paper, Divider } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DirectionsIcon from '@mui/icons-material/Directions';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CarParkDetail from './CarParkDetail';

function CarParkInfoItem(props) {

    const favoritesCtx = useContext(FavoritesContext);

    const itemIsFavorite = favoritesCtx.itemIsFavorite(props.id);

    return (
        // <li className={classes.item}>
        <Box mt={2}>
            <Card>
                <CardMedia
                    component="img"
                    alt={props.name}
                    height="100"
                    image={props.image}
                />
                <CardContent>
                    <Box>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid onClick={() => props.toCarparkDetailPage(props.id)} item xs={8} md={9}>
                                <Typography gutterBottom variant="h5" component="div">
                                    {props.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {props.address}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <Stack direction="horizontal" gap={1}>
                                    {/* <Paper elevation={3} className="ms-auto" style={{ backgroundColor: '#66bb6a', padding: '5px 5px 5px 5px' }}> */}
                                        {props.privateCarVacancy > 0
                                            ? <Paper elevation={3} className="ms-auto" style={{ backgroundColor: '#66bb6a', padding: '5px 5px 5px 5px' }}>
                                                <Typography variant="h4" color="text.primary" style={{ textAlign: 'center' }}>
                                                    {props.privateCarVacancy}
                                                </Typography>
                                                <Divider />
                                                <Typography variant="body2" color="text.primary" style={{ textAlign: 'center' }}>
                                                    {props.privateCarLastUpdate}
                                                </Typography>
                                            </Paper>
                                            :
                                            <Paper elevation={3} className="ms-auto" style={{ backgroundColor: '#ef5350', padding: '5px 5px 5px 5px' }}>
                                                <Typography variant="h4" color="text.primary" style={{ textAlign: 'center' }}>
                                                    {props.privateCarVacancy}
                                                </Typography>
                                                <Divider />
                                                <Typography variant="body2" color="text.primary" style={{ textAlign: 'center' }}>
                                                    {props.privateCarLastUpdate}
                                                </Typography>
                                            </Paper>
                                        }
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
                <CardActions>
                    <Button variant="contained" endIcon={<InfoIcon />} onClick={() => props.handleOpen(props)}>
                        詳情
                    </Button>
                    <Button variant="contained" endIcon={<DirectionsIcon />} onClick={() => props.openGoogleMap(props.address)}>
                        前往
                    </Button >
                    <Button variant="contained" endIcon={<FavoriteIcon />}>
                        賣飛佛
                    </Button>
                </CardActions>
            </Card>
            
        </Box>
    );
}

export default CarParkInfoItem;