import React, { useState, useEffect, forwardRef, Fragment } from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from "@mui/material/styles";
import "../index.css";
import { toDateString } from '../util/TimeUtil';
import { Key } from '../imgurKey';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { API_URL } from '../environment';

// React Router
import { useNavigate } from "react-router-dom";

// Components
import NewContentForm from '../components/management/NewContentForm';
import NewCarParkForm from '../components/management/NewCarParkForm';
import NewParkBinForm from '../components/management/NewParkBinForm';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function createData(id, carparkId, name, address, district, phone, latitude, longitude, image) {
    return { id, carparkId, name, district, address, phone, latitude, longitude, image };
}

const rows = [];

function ContentManagementPage() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const [openCopyDialog, setOpenCopyDialog] = React.useState(false);
    const [imgurUrl, setImgurUrl] = React.useState('');
    const [loadCarpark, setCarpark] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const urls = [
            API_URL + `/api/carpark/edit`
        ]
        try {
            let carparks;
            const getCarpark = async () => {
                [carparks] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);
                setCarpark(carparks);

                for (var carpark of carparks) {
                    let id = carpark['id']
                    let carparkId = carpark['carparkId'];
                    let name = carpark['name'];
                    let address = carpark['address'];
                    let district = carpark['district'];
                    let phone = carpark['contactNo'];
                    let latitude = carpark['latitude'];
                    let longitude = carpark['longitude'];
                    let image = carpark['carpark_photo'];

                    rows.push(createData(id, carparkId, name, address, district, phone, latitude, longitude, image));
                }
            }
            getCarpark();

        } catch (error) {
            console.log(error);
        }

    }, []);

    if (isLoading) {
        return (
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const CopyDialog = () => {
        return (
            <Dialog
                open={openCopyDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <Container>
                    <Box sx={{ mt: 5, mb: 5, mx: 5 }} >
                        <Typography variant="h6" gutterBottom component="div">
                            上傳成功
                        </Typography>
                        <TextField
                            disabled
                            hiddenLabel
                            id="imgur-url-text"
                            defaultValue={imgurUrl}
                            variant="filled"
                            size="small"
                        />
                        <label htmlFor="icon-content-copy">
                            <IconButton color="primary" aria-label="copy text" component="span" onClick={() => copyText()}>
                                <ContentCopyIcon />
                            </IconButton>
                        </label>
                    </Box>
                </Container>
            </Dialog>
        )
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setOpenCopyDialog(false);
    };

    const copyText = () => {
        var copyText = document.getElementById("imgur-url-text");
        console.log('copytext: ' + copyText.value);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        alert("Copied the text: " + copyText.value);
    }


    const handleImageUpload = (imageData) => {
        Swal.fire({
            title: '確認?',
            icon: 'info',
            html: "<img src='" + URL.createObjectURL(imageData.imageFile) + "' style='width:150px;'>",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '上傳',
            cancelButtonText: '取消',
        }).then((result) => {
            if (result.isConfirmed) {
                const d = new FormData();
                d.append('image', imageData.imageFile);
                d.append('title', 'carpark');

                fetch(
                    "https://api.imgur.com/3/image/",
                    {
                        method: 'POST',
                        body: d,
                        headers: {
                            "Authorization": "Client-ID " + Key,
                        },
                    }
                )
                    .then(res => res.json())
                    .then((result) => {
                        if (result.data.link) {
                            setOpenCopyDialog(true);
                            setImgurUrl(result.data.link);
                        }
                        document.getElementById("content").value += "<img src=\"" + result.data.link + "\" alt=\"" + result.data.title + "\" />";
                    })
            }
        })
    }

    const handleBoldBtn = () => {
        document.getElementById("content").value += "<b></b>";
    }

    const handleItalicBtn = () => {
        document.getElementById("content").value += "<i></i>";
    }

    const handleUnderlinedBtn = () => {
        document.getElementById("content").value += "<u></u>";
    }

    function addContentHandler(contentData) {
        console.log('addContentHandler...');

        const d = new FormData();
        d.append('image', contentData.imageFile);
        d.append('title', 'carpark');

        fetch(
            "https://api.imgur.com/3/image/",
            {
                method: 'POST',
                body: d,
                headers: {
                    "Authorization": "Client-ID " + Key,
                },
            }
        )
            .then(res => res.json())
            .then((result) => {
                if (result.data.link) {
                    contentData.image = result.data.link;
                    fetch(
                        API_URL + "/api/promotion",
                        {
                            method: "POST",
                            body: JSON.stringify(contentData),
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    ).then((res) => {
                        if (res.status == '201') {
                            Swal.fire({
                                title: '完成!',
                                text: '已加入',
                                icon: 'success',
                                confirmButtonText: '關閉'
                            })
                            navigate("/management/content/add");
                        } else {
                            Swal.fire({
                                title: '失敗!',
                                text: '發生未知的錯誤',
                                icon: 'info',
                                confirmButtonText: '關閉'
                            })
                        }
                    })
                } else {
                    Swal.fire({
                        title: '錯誤!',
                        text: '上傳相片失敗',
                        icon: 'error',
                        confirmButtonText: '關閉'
                    })
                }

            });


    }

    function addCarParkHandler(contentData) {
        console.log('addCarParkHandler...');
        const d = new FormData();
        d.append('image', contentData.carparkPhoto);
        d.append('title', 'carpark');

        fetch(
            "https://api.imgur.com/3/image/",
            {
                method: 'POST',
                body: d,
                headers: {
                    "Authorization": "Client-ID " + Key,
                },
            }
        )
            .then(res => res.json())
            .then((result) => {
                if (result.data.link) {
                    contentData.carparkPhoto = result.data.link;
                    fetch(
                        API_URL + "/api/carpark",
                        {
                            method: "POST",
                            body: JSON.stringify(contentData),
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    ).then((res) => {
                        if (res.status == '201') {
                            Swal.fire({
                                title: '完成!',
                                text: '已加入',
                                icon: 'success',
                                confirmButtonText: '關閉'
                            })
                            navigate("/management/content/add");
                        } else {
                            Swal.fire({
                                title: '失敗!',
                                text: '資料錯誤',
                                icon: 'info',
                                confirmButtonText: '關閉'
                            })
                        }

                    });
                } else {
                    Swal.fire({
                        title: '錯誤!',
                        text: '上傳相片失敗',
                        icon: 'error',
                        confirmButtonText: '關閉'
                    })
                }

            });


    }

    function addParkBinHandler(contentData) {
        console.log('addParkBinHandler...');

        const d = new FormData();
        d.append('image', contentData.imageFile);
        d.append('title', 'parkbin');

        fetch(
            "https://api.imgur.com/3/image/",
            {
                method: 'POST',
                body: d,
                headers: {
                    "Authorization": "Client-ID " + Key,
                },
            }
        )
            .then(res => res.json())
            .then((result) => {
                if (result.data.link) {
                    contentData.photoBanner = result.data.link;
                    fetch(
                        API_URL + "/api/parkbin",
                        {
                            method: "POST",
                            body: JSON.stringify(contentData),
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    ).then((res) => {
                        if (res.status == '201') {
                            Swal.fire({
                                title: '完成!',
                                text: '已加入',
                                icon: 'success',
                                confirmButtonText: '關閉'
                            })
                            navigate("/management/content/add");
                        } else {
                            Swal.fire({
                                title: '失敗!',
                                text: '發生未知的錯誤',
                                icon: 'info',
                                confirmButtonText: '關閉'
                            })
                        }
                    })
                } else {
                    Swal.fire({
                        title: '錯誤!',
                        text: '上傳相片失敗',
                        icon: 'error',
                        confirmButtonText: '關閉'
                    })
                }

            });


    }

    return (
        <RootBox>
            <RootContainer className={classes.root}>
                <CopyDialog handleClose={handleClose}/>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="泊車優惠" {...a11yProps(0)} />
                        <Tab label="停車場" {...a11yProps(1)} />
                        <Tab label="泊邊好" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <NewContentForm 
                        onAddContent={addContentHandler} 
                        handleImageUpload={handleImageUpload} 
                        copyText={copyText} 
                        handleBoldBtn={handleBoldBtn}
                        handleItalicBtn={handleItalicBtn}
                        carparks={rows}
                        handleUnderlinedBtn={handleUnderlinedBtn} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <NewCarParkForm 
                        onAddContent={addCarParkHandler} 
                        handleImageUpload={handleImageUpload} 
                        copyText={copyText} 
                        handleBoldBtn={handleBoldBtn}
                        handleItalicBtn={handleItalicBtn}
                        carparks={rows}
                        handleUnderlinedBtn={handleUnderlinedBtn}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <NewParkBinForm 
                        onAddContent={addParkBinHandler} 
                        handleImageUpload={handleImageUpload} 
                        copyText={copyText} 
                        handleBoldBtn={handleBoldBtn}
                        handleItalicBtn={handleItalicBtn}
                        carparks={rows}
                        handleUnderlinedBtn={handleUnderlinedBtn}/>
                </TabPanel>
            </RootContainer>
        </RootBox>
    )
}

const PREFIX = "contentMgn";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const RootContainer = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    }
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('/img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

export default ContentManagementPage;