import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import { Box } from '@mui/material';

const CarParkDetail = (props) => {
    var remarkItem = props.carparkDetail.remark.replace('\n', '<br>');
    remarkItem = remarkItem.split('<br>');
    console.log(remarkItem);
    return (
        <div>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        size="medium"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        // sx={{ mr: 2 }}
                    >
                        <LocalParkingIcon />
                    </IconButton>
                    <Typography sx={{ flex: 1 }} variant="h6" component="div">
                        停車場資料
                    </Typography>
                    <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-haspopup="true"
                            onClick={() => props.handleClose()}
                            color="inherit"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <List>
                <ListItem button>
                    <ListItemText primary={props.carparkDetail.name} />
                </ListItem>
                <Divider />
                <ListItem button>
                    <ListItemText
                        primary={props.carparkDetail.address}
                    // secondary="Tethys"
                    />
                </ListItem>
                <Divider />
                <ListItem button>
                    <ListItemText
                        primary={
                            remarkItem.map((item) => (
                                <Box>{item}</Box>
                            ))
                        }
                    // secondary="Tethys"
                    />
                </ListItem>
                <Divider />
                <ListItem button>
                    <ListItemText
                        primary={props.carparkDetail.website}
                    // secondary="Tethys"
                    />
                </ListItem>
            </List>
        </div>
    );
}

export default CarParkDetail;