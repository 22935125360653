import { Box } from '@mui/material';
import {
    WhatsappShareButton, EmailShareButton, FacebookShareButton, TelegramShareButton,
    WhatsappIcon, EmailIcon, FacebookIcon, TelegramIcon
} from "react-share";

function SocialMedia(props) {
    // const shareUrl = 'http://github.com';
    // const title = 'GitHub';

    return (
        <Box sx={{ p:1 }}>
            <WhatsappShareButton
                url={props.url}
                title={props.title}
                separator=":: "
                className="Demo__some-network__share-button"
            >
                <WhatsappIcon size={32} round style={{ marginRight: '5px' }} />
            </WhatsappShareButton>
            <EmailShareButton
                url={props.url}
                title={props.title}
                separator=":: "
                className="Demo__some-network__share-button"
            >
                <EmailIcon size={32} round style={{ marginRight: '5px' }} />
            </EmailShareButton>
            <FacebookShareButton
                url={props.url}
                title={props.title}
                separator=":: "
                className="Demo__some-network__share-button"
            >
                <FacebookIcon size={32} round style={{ marginRight: '5px' }} />
            </FacebookShareButton>
            <TelegramShareButton
                url={props.url}
                title={props.title}
                separator=":: "
                className="Demo__some-network__share-button"
            >
                <TelegramIcon size={32} round style={{ marginRight: '5px' }} />
            </TelegramShareButton>
        </Box>

    )
}

export default SocialMedia;