import { useState, useEffect, forwardRef, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import classes from "../meetups/NewMeetupForm.module.css";

// Hooks
import { useRef } from "react";

function EditCarParkForm(props) {
    const idInputRef = useRef();
    const carparkIdInputRef = useRef();
    const carparkNameInputRef = useRef();
    const imageInputRef = useRef();
    const latitudeInputRef = useRef();
    const longitudeInputRef = useRef();

    function submitHandler(event) {
        event.preventDefault();

        const id = idInputRef.current.value;
        const carparkId = carparkIdInputRef.current.value;
        const carparkName = carparkNameInputRef.current.value;
        // const image = document.getElementsByClassName('input-image')[0].files[0];
        // const district = districtInputRef.current.value;
        // const tel = telInputRef.current.value;
        // const address = addressInputRef.current.value;
        const latitude = latitudeInputRef.current.value;
        const longitude = longitudeInputRef.current.value;
        const lastModifiedDate = new Date();

        const contentData = {
            id: id,
            carparkId: carparkId,
            name: carparkName,
            // carparkPhoto: image,
            district: '',
            tel: '',
            address: '',
            latitude: latitude,
            longitude: longitude,

            lastModifiedDate: lastModifiedDate
        };

        console.log(contentData);

        props.editCarPark(contentData);
    }

    return (
        <>
            <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="h4"
                color="text.primary"
            >
                更改停車場資料
            </Typography>
            <form className={classes.form} onSubmit={submitHandler}>
                <TextField required id="id" defaultValue={props.carparkDetail.id} inputRef={idInputRef} sx={{ width: '100%' }} />
                <div className={classes.control}>
                    <label htmlFor="description">停車場編號</label>
                    <TextField required id="carparkId" defaultValue={props.carparkDetail.carparkId} inputRef={carparkIdInputRef} sx={{ width: '100%' }} />
                </div>
                <div className={classes.control}>
                    <label htmlFor="title">停車場名稱</label>
                    <TextField required id="carparkName" defaultValue={props.carparkDetail.name} inputRef={carparkNameInputRef} sx={{ width: '100%' }} />
                </div>
                <div className={classes.control}>
                    <label htmlFor="image">圖片</label>
                    <img src={props.carparkDetail.image} />
                    {/* <input type="file" className="input-image" required id="image" ref={imageInputRef} /> */}
                </div>
                <div className={classes.control}>
                    <label htmlFor="latitude">緯度</label>
                    <TextField required id="latitude" defaultValue={props.carparkDetail.latitude} inputRef={latitudeInputRef} sx={{ width: '100%' }} />
                </div>
                <div className={classes.control}>
                    <label htmlFor="longitude">經度</label>
                    <TextField required id="longitude" defaultValue={props.carparkDetail.longitude} inputRef={longitudeInputRef} sx={{ width: '100%' }} />
                </div>

                <div className={classes.actions}>
                    <button>確定</button>
                </div>
            </form>
        </>
    )
}

export default EditCarParkForm;