import CarParkInfoItem from './CarParkInfoItem';
import classes from './CarParkInfoList.module.css';
import { timeSince } from '../../util/TimeUtil';
import { Box, Grid, Stack } from '@mui/material';

function CarParkInfoList({carparks, handleOpen, handleClose, openGoogleMap, toCarparkDetailPage}) {
    // return (
    //     <Box>
    //         {carparks.map((carpark, i) => (
    //             <CarParkInfoItem
    //                 key={carpark.park_Id}
    //                 id={carpark.park_Id}
    //                 image={carpark.renditionUrls === undefined ? '' : carpark.renditionUrls['carpark_photo']}
    //                 name={carpark.name}
    //                 address={carpark.displayAddress}
    //                 website={carpark.website}
    //                 remark={carpark.heightLimits[0]['remark']}
    //                 privateCarVacancy={(!carpark.hasOwnProperty('privateCar') || carpark.privateCar[0] === undefined) ? 'N/A' : carpark.privateCar[0]['vacancy']}
    //                 privateCarLastUpdate={(!carpark.hasOwnProperty('privateCar') || carpark.privateCar[0] === undefined) ? 'N/A' : timeSince(Date.parse(carpark.privateCar[0]['lastupdate']))}
    //                 handleOpen={handleOpen}
    //                 handleClose={handleClose}
    //                 openGoogleMap={openGoogleMap}
    //             />
    //         ))}
    //     </Box>
    // );
    return (
    carparks.map((carpark, i) => (
            
                <Stack key={i} direction="row" alignItems="center" spacing={2} sx={{ mb: 1 }}>
                    <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', }}>
                        <Grid item key={carpark.list[0].park_Id} xs={12} sm={12} md={4}>
                            <CarParkInfoItem
                            id={carpark.list[0].park_Id}
                            image={carpark.list[0].renditionUrls === undefined ? '' : carpark.list[0].renditionUrls['carpark_photo']}
                            name={carpark.list[0].name}
                            address={carpark.list[0].displayAddress}
                            website={carpark.list[0].website}
                            remark={carpark.list[0].heightLimits[0]['remark']}
                            privateCarVacancy={(!carpark.list[0].hasOwnProperty('privateCar') || carpark.list[0].privateCar[0] === undefined) ? 'N/A' : carpark.list[0].privateCar[0]['vacancy']}
                            privateCarLastUpdate={(!carpark.list[0].hasOwnProperty('privateCar') || carpark.list[0].privateCar[0] === undefined) ? 'N/A' : timeSince(Date.parse(carpark.list[0].privateCar[0]['lastupdate']))}
                            handleOpen={handleOpen}
                            handleClose={handleClose}
                            openGoogleMap={openGoogleMap}
                            toCarparkDetailPage={toCarparkDetailPage}
                        />
                        </Grid>
                        {carpark.list[1] ? 
                        <Grid item key={carpark.list[1].park_Id} xs={12} sm={12} md={4}>
                            <CarParkInfoItem
                            id={carpark.list[1].park_Id}
                            image={carpark.list[1].renditionUrls === undefined ? '' : carpark.list[0].renditionUrls['carpark_photo']}
                            name={carpark.list[1].name}
                            address={carpark.list[1].displayAddress}
                            website={carpark.list[1].website}
                            remark={carpark.list[1].heightLimits[0]['remark']}
                            privateCarVacancy={(!carpark.list[1].hasOwnProperty('privateCar') || carpark.list[1].privateCar[0] === undefined) ? 'N/A' : carpark.list[1].privateCar[0]['vacancy']}
                            privateCarLastUpdate={(!carpark.list[1].hasOwnProperty('privateCar') || carpark.list[1].privateCar[0] === undefined) ? 'N/A' : timeSince(Date.parse(carpark.list[1].privateCar[0]['lastupdate']))}
                            handleOpen={handleOpen}
                            handleClose={handleClose}
                            openGoogleMap={openGoogleMap}
                            toCarparkDetailPage={toCarparkDetailPage}
                        />
                        </Grid> : <></>
                        }
                        {carpark.list[2] ? 
                        <Grid item key={carpark.list[2].park_Id} xs={12} sm={12} md={4}>
                            <CarParkInfoItem
                            id={carpark.list[2].park_Id}
                            image={carpark.list[2].renditionUrls === undefined ? '' : carpark.list[2].renditionUrls['carpark_photo']}
                            name={carpark.list[2].name}
                            address={carpark.list[2].displayAddress}
                            website={carpark.list[2].website}
                            remark={carpark.list[2].heightLimits[0]['remark']}
                            privateCarVacancy={(!carpark.list[2].hasOwnProperty('privateCar') || carpark.list[2].privateCar[0] === undefined) ? 'N/A' : carpark.list[2].privateCar[0]['vacancy']}
                            privateCarLastUpdate={(!carpark.list[2].hasOwnProperty('privateCar') || carpark.list[2].privateCar[0] === undefined) ? 'N/A' : timeSince(Date.parse(carpark.list[2].privateCar[0]['lastupdate']))}
                            handleOpen={handleOpen}
                            handleClose={handleClose}
                            openGoogleMap={openGoogleMap}
                            toCarparkDetailPage={toCarparkDetailPage}
                        />
                        </Grid> : <></>
                        }
                    </Grid>
                </Stack>
    
            
    ))
    )
}

export default CarParkInfoList;