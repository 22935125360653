import React, { useState, useEffect, Fragment, useRef, forwardRef } from 'react'
import { GoogleMap, useJsApiLoader, MarkerClusterer, Marker } from '@react-google-maps/api';
import { Key } from '../googleMapKey';
import GasStationList from '../components/gasstation/gasStationList';
import { Box, Button, Stack, CircularProgress, Dialog, Slide } from '@mui/material';
import { styled } from "@mui/material/styles";
import { Opacity } from '@mui/icons-material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import MarkerDetail from "../components/carpark/MarkerDetail";
import Backdrop from "@mui/material/Backdrop";
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import { API_URL } from '../environment';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const defaultMapOptions = {
  disableDefaultUI: true,
};

const gasStations = [
  { district: "香港", id: "域多利道", tel: "+852 2551 9346", address: "域多利道 555 號 （近碧瑤灣）", pos: { lat: 22.262175283201383, lng: 114.13307248009517 } },
  { district: "香港", id: "薄扶林", tel: "+852 2495 2585", address: "薄扶林道100號", pos: { lat: 22.2749921, lng: 114.12175983 } },
  { district: "香港", id: "鰂魚涌", tel: "+852 2561 3084 ", address: "鰂魚涌英皇道 979 號 A", pos: { lat: 22.287612, lng: 114.1836594 } },
  { district: "香港", id: "電氣道", tel: "+852 2570 3034 ", address: "北角電氣道 169-171 號", pos: { lat: 22.2872459, lng: 114.2023239 } },
]

function CarparkMapPage(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: Key
  })
  const center = {
    lat: props.carpark['latitude'],
    lng: props.carpark['longitude']
  };

//   console.log("latitude: " + latitude + ", longitude: " + longitude);

  // API Url
  var CARPARK_API = `https://carparkapi.dllmon99.com/api/carpark`;
  var GASSTATION_API = `https://carparkapi.dllmon99.com/api/gasstation`;

  // const [map, setMap] = React.useState(null);
  const [mapRef, setMapRef] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedCarParks, setLoadedCarParks] = useState([]);
  const [loadedGasStations, setLoadedGasStations] = useState([]);
  const [loadedParkingMeters, setLoadedParkingMeters] = useState([]);
  const [bound, setBound] = useState();
  // const bound = useRef();
  const prevBound = useRef();
  const minimumMovement = useRef(0.02);
  const prevLatitdueDiff = useRef(0);
  const prevLongitudeDiff = useRef(0);
  const zoom = useRef();

  // for marker detail
  const [open, setOpen] = useState(false);
  const [markerDetail, setMarkerDetail] = useState([]);

  // control on off category
  const [enableGasStation, setEnableGasStation] = useState(true);
  const [enableCarPark, setEnableCarPark] = useState(true);
  const [enableParkingMeter, setEnableParkingMeter] = useState(true);

  const gasStationList = useRef();
  const carParkList = useRef();
  const parkingMeterList = useRef();

  const [carparkButtonVariant, setCarparkButtonVariant] = useState('contained');
  const [parkingMeterButtonVariant, setParkingMeterButtonVariant] = useState('contained');
  const [gasStationButtonVariant, setGasStationButtonVariant] = useState('contained');

  const handleCarParkButtonVariantChange = () => {
    if (carparkButtonVariant === 'outlined') {
      setCarparkButtonVariant('contained');
    } else {
      setCarparkButtonVariant('outlined');
    }
  }

  const handleParkingMeterButtonVariantChange = () => {
    if (parkingMeterButtonVariant === 'outlined') {
      setParkingMeterButtonVariant('contained');
    } else {
      setParkingMeterButtonVariant('outlined');
    }
  }

  const handleGasStationButtonVariantChange = () => {
    if (gasStationButtonVariant === 'outlined') {
      setGasStationButtonVariant('contained');
    } else {
      setGasStationButtonVariant('outlined');
    }
  }

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // Success function
        showPosition,
        // Error function
        null,
        // Options. See MDN for details.
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    console.log("Latitude: " + position.coords.latitude +
      ", Longitude: " + position.coords.longitude);
  }

  // getLocation();

  useEffect(() => {

    var extent = "";
    if (bound !== undefined) {
      const neLat = bound.getNorthEast().lat();
      const neLng = bound.getNorthEast().lng();
      const swLat = bound.getSouthWest().lat();
      const swLng = bound.getSouthWest().lng();
      // console.log('ne lat: ' + bound.current.getNorthEast().lat() + ', ne lng: ' + bound.current.getNorthEast().lng());
      // console.log('sw lat: ' + bound.current.getSouthWest().lat() + ', sw lng: ' + bound.current.getSouthWest().lng());
      extent = swLat + "," + neLat + "," + swLng + "," + neLng;
      // GASSTATION_API += `?extent=` + extent;
    }

    const urls = [
      // CARPARK_API,
      // GASSTATION_API
      API_URL + `/api/carpark`,
      API_URL + `/api/gasstation`    ]

    try {
      let carparkJson, gasstationJson;
      const getMapData = async () => {
        [carparkJson, gasstationJson ] = await Promise.all(
          urls.map((url) => fetch(url)
            .then((res) => { return res.json(); })
          )
        );
        setIsLoading(false);

        // console.log('carpark: ' + JSON.stringify(carparkJson));
        // console.log('gasstationJson: ' + JSON.stringify(gasstationJson));
        if (enableGasStation) {
          setLoadedGasStations(gasstationJson);
          gasStationList.current = gasstationJson;
        }

        if (enableCarPark) {
          setLoadedCarParks(carparkJson);
          carParkList.current = carparkJson;
        }

      }

      getMapData();

    } catch (error) {
      console.log(error);
    }

  }, [bound]);

  const onLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    // set initial center
    bounds.extend({ lat: 22.284470, lng: 114.166597 });
    bounds.extend({ lat: 22.290453, lng: 114.175881 });
    map.fitBounds(bounds);

    setMapRef(map);
    prevBound.current = bounds;
    setBound(bounds);
    console.log('initial bounds: ' + bound);
  }

  const onUnmount = React.useCallback(function callback(map) {
    setMapRef(null)
  }, [])

  const openGoogleMap = (address) => {
    window.open('https://www.google.com/maps/search/?api=1&query=' + address);
  }

  function handleMarkerOnClick(markerDetail) {
    setMarkerDetail(markerDetail);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  function onCenterChanged(map) {
    if (mapRef) {
      const newCenter = mapRef.getCenter();
      const newBounds = mapRef.getBounds();
      // console.log('new bounds: ' + newBounds);

      if (newBounds) {
        prevLatitdueDiff.current += Math.abs(prevBound.current.getSouthWest().lat() - newBounds.getSouthWest().lat());
        prevLongitudeDiff.current += Math.abs(prevBound.current.getSouthWest().lng() - newBounds.getSouthWest().lng());
        prevBound.current = newBounds;

      }

      // console.log('prevLatitdueDiff: ' + prevLatitdueDiff.current + ', prevLongitudeDiff: ' + prevLongitudeDiff.current);

      if (zoom.current < 14) {
        setLoadedGasStations([]);
        setLoadedCarParks([]);
        return;
      }

      if (newBounds && JSON.stringify(gasStationList) != '{}' && (prevLatitdueDiff.current > minimumMovement.current || prevLongitudeDiff.current > minimumMovement.current)) {
        setLoadedGasStations([]);
        setLoadedCarParks([]);
        if (JSON.stringify(gasStationList) != '{}') {
          const sortedGasstationJson = [];
          const neLat = newBounds.getNorthEast().lat();
          const neLng = newBounds.getNorthEast().lng();
          const swLat = newBounds.getSouthWest().lat();
          const swLng = newBounds.getSouthWest().lng();
          gasStationList.current.forEach((gasstation) => {
            if (gasstation.latitude >= swLat && gasstation.latitude <= neLat
              && gasstation.longitude >= swLng && gasstation.longitude <= neLng) {
              sortedGasstationJson.push(gasstation);
            }

          })
          setLoadedGasStations(sortedGasstationJson);

          const gasstationOnScreen = sortedGasstationJson;
        }
        if (JSON.stringify(carParkList) != '{}') {
          const sortedCarParkJson = [];
          const neLat = newBounds.getNorthEast().lat();
          const neLng = newBounds.getNorthEast().lng();
          const swLat = newBounds.getSouthWest().lat();
          const swLng = newBounds.getSouthWest().lng();
          carParkList.current.forEach((carpark) => {
            console.log('carpark.latitude: ' + neLat + ', carpark.longitude: ' + neLng);
            if (parseFloat(carpark.latitude) >= swLat && parseFloat(carpark.latitude) <= neLat
              && parseFloat(carpark.longitude) >= swLng && parseFloat(carpark.longitude) <= neLng) {
              sortedCarParkJson.push(carParkList);
            }

          })
          setLoadedCarParks(sortedCarParkJson);

          const carParkOnScreen = sortedCarParkJson;
          // console.log('carParkOnScreen: ' + carParkOnScreen.length);
        }


        // carParkList.current.forEach((carpark) => {
        //   if (carpark.latitude >= swLat && carpark.latitude <= neLat
        //     && carpark.longitude >= swLng && carpark.longitude <= neLng) {
        //       sortedCarParkJson.push(carpark);
        //   }

        // })
        // setLoadedCarParks(sortedCarParkJson);
        // console.log('sortCarParkList length: ' + sortedCarParkJson.length);
        setBound(newBounds);

        // reset latitude diff and longitutde diff to 0
        prevLatitdueDiff.current = 0;
        prevLongitudeDiff.current = 0;
      }

    }
  }

  function onZoomChanged(map) {
    if (mapRef) {
      zoom.current = mapRef.getZoom();
    }
  }

  function createKey(location) {
    return location.lat + location.lng
  }

  const markerClusterOptions = {
    gridSize: 20,
    minimumClusterSize: 10
  }

  return isLoaded ? (

    <Fragment>
      {/* <div className={classes.map}> */}
      <div
        style={{
          position: "relative",
          zIndex: 0,
          width: "100%", // or you can use width: '100vw'
          height: "90vh" // or you can use height: '100vh'
        }}
        className={classes.root}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <GoogleMap
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
          // onCenterChanged={onCenterChanged}
          center={center}
          // zoom={10}
          options={defaultMapOptions}
          onZoomChanged={onZoomChanged}
          spacing={{ xs: 1, md: 2 }}
        >
          <MarkerClusterer>
            {(clusterer) =>
              loadedCarParks.map((e) =>
                <Marker
                  clusterer={clusterer}
                  key={e.carparkId}
                  label={e.hasOwnProperty('vacancyList') ? (e.vacancyList.length < 1 ? '0' : String(e.vacancyList[0]['vacancy'])) : '0'}
                  icon={{
                    url: "/img/carpark-48.png",
                    labelOrigin: new window.google.maps.Point(24, 15),
                  }}
                  size={{ width: 36, height: 36 }}
                  position={{ lat: e.latitude, lng: e.longitude }}
                  // onLoad={console.log(e.pos)}
                  onClick={() => { handleMarkerOnClick(e); }}  >
                </Marker>
              )
            }
          </MarkerClusterer>
          <MarkerClusterer>
            {(clusterer) =>
              loadedGasStations.map((e) =>
                <Marker
                  clusterer={clusterer}
                  key={e.id}
                  icon={"/shell.png"}
                  size={{ width: 36, height: 36 }}
                  position={{ lat: e.latitude, lng: e.longitude }}
                  // onLoad={console.log(e.pos)}
                  onClick={() => { handleMarkerOnClick(e); }}  >
                </Marker>
              )
            }
          </MarkerClusterer>
        </GoogleMap>
        <div
          style={{
            zIndex: 1,
            position: "absolute",
            top: 10,
            left: 10,
            // backgroundColor: "white", // you can use any color value
            width: "90%", // or you can use width: any_number
            height: "10%", // or you can use height: any_number
            // opacity: 0.3
          }}
        >
          <Stack direction="row" spacing={3}>
            <Button variant={carparkButtonVariant} startIcon={<DirectionsCarIcon />}
              onClick={() => {
                handleCarParkButtonVariantChange();
                if (enableCarPark) {
                  setEnableCarPark(false);
                  setLoadedCarParks([]);
                } else {
                  setEnableCarPark(true);
                  setLoadedCarParks(carParkList.current);
                }
              }}
            >
              停車場
            </Button>
            <Button variant={parkingMeterButtonVariant} startIcon={<LocalParkingIcon />}
              onClick={() => {
                handleParkingMeterButtonVariantChange();
                if (enableParkingMeter) {
                  setEnableParkingMeter(false);
                  setLoadedParkingMeters([]);
                } else {
                  setEnableParkingMeter(true);
                  setLoadedParkingMeters(parkingMeterList.current);
                }
              }}
            >
              咪錶位
            </Button>
            <Button variant="contained" startIcon={<LocalCarWashIcon />}>
              洗車
            </Button>
            <Button variant={gasStationButtonVariant} startIcon={<LocalGasStationIcon />}
              onClick={() => {
                handleGasStationButtonVariantChange();
                if (enableGasStation) {
                  setEnableGasStation(false);
                  setLoadedGasStations([]); // for rendering
                  // gasStationList.current = []; // TBC: for sorting
                } else {
                  setEnableGasStation(true);
                  setLoadedGasStations(gasStationList.current);
                }
              }}
            >
              油站
            </Button>
          </Stack>
        </div>
        <DialogItem
          fullScreen
          open={open}
          className={classes.root}
          onClose={handleClose}
          TransitionComponent={Transition}
          hideBackdrop={true}
        // disableBackdropClick
        // disableAutoFocus
        >
          <MarkerDetail handleClose={handleClose} markerDetail={markerDetail} openGoogleMap={openGoogleMap} />
        </DialogItem>
      </div>
    </Fragment>
  ) : <></>
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PREFIX = "map";

const classes = {
  root: `${PREFIX}-root`,
};

const DialogItem = styled(Dialog)(({ theme }) => ({
  [`&.${classes.root}`]: {
    top: `60vh !important`,
    bottom: `50px !important`,
    left: `10px !important`,
    right: `10px !important`,
  },
}));

export default CarparkMapPage;