import { useState, useEffect, useRef } from "react";
// React Router
import { useNavigate } from "react-router-dom";
import { propTypes } from "prop-types";
import { Grow, Box, Typography } from "@mui/material";
import './Layout.module.css';
import { API_URL } from '../../environment';


const Fader = ({ text }) => {
    const [fadeprop, setFadeProp] = useState({
        fade: 'fade-in',
    });
    const [checked, setChecked] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const parkBin = useRef([]);
    const navigate = useNavigate();

    useEffect(() => {
        const urls = [
            API_URL + `/api/parkbin?limit=10`
        ]
        try {
            let parkbin;
            const getParkBin = async () => {
                [parkbin] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );

                parkBin.current = parkbin;

            }
            getParkBin();

        } catch (error) {
            console.log(error);
        }

    }, []);

    useEffect(() => {
        const timeout = setInterval(() => {
            if (fadeprop.fade === 'fade-in') {
                setFadeProp({
                    fade: 'fade-out'
                })
                setChecked((prev) => !prev);
            } else {
                setFadeProp({
                    fade: 'fade-in'
                })
                setChecked((prev) => !prev);
            }
        }, 2500)
        return () => clearInterval(timeout);

    }, [fadeprop, parkBin])

    return parkBin.current.length > 0 ? (
        <>
            {/* <h1 className={fadeprop.fade}>{title[Math.floor(Math.random() * title.length)]}</h1> */}
            <LatestNews navigate={navigate} parkBin={parkBin.current} checked={checked}/>
        </>
    ) : <Box></Box>
}

const LatestNews = (props) => {
    const seq = Math.floor(Math.random() * props.parkBin.length);

    return (
        <Box
            elevation={4}
            onClick={() => { props.navigate(`/parkbin/` + props.parkBin[seq].id) }}
        >
            <Grow direction="right" in={props.checked}>
            <Typography 
                align='left' 
                sx={{ 
                    typography: { sm: 'subtitle1', xs: 'subtitle1', md: 'h6' }, 
                    mb: 1,
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                 }}>
            🚗🚗🚗 {props.parkBin[seq].title} 🚗🚗🚗
            </Typography>
            </Grow>
        </Box>
    )
}

Fader.defaultProps = {
    text: 'Hello there'
}

// Fader.propTypes = {
//     text: propTypes.,
// }

export default Fader;