import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import SettingsIcon from "@mui/icons-material/Settings";
import LocalParkingIcon from "@mui/icons-material/LocalParking"
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import InfoIcon from '@mui/icons-material/Info';
import MapIcon from '@mui/icons-material/Map';
import HomeIcon from "@mui/icons-material/Home";
import Cloud from '@mui/icons-material/Cloud';
import DiscountIcon from '@mui/icons-material/Discount';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import { styled } from "@mui/material/styles";
import { Link, useLocation, useHistory, useNavigate } from "react-router-dom";
import { useAuthDispatch, useAuthState } from "../../store/app-context";
import { logout } from "../../store/action";

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Header = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElTest, setAnchorElTest] = React.useState(null);
  const [anchorElManagement, setAnchorElManagement] = React.useState(null);

  const pages = ['十八區點泊好?', '泊車優惠', '登入'];

  const auth = useAuthState();
  const dispatch = useAuthDispatch();
  const [loggedIn, setLoggedIn] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenTestMenu = (event) => {
    setAnchorElTest(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenManagementMenu = (event) => {
    setAnchorElManagement(event.currentTarget)
  }

  const handleCloseManagementMenu = () => {
    setAnchorElManagement(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseTestMenu = () => {
    setAnchorElTest(null);
  };

  const handleLogoutBtnClick = () => {
    logout(dispatch);
  };

  const ManagementButton = () => {
    return loggedIn ? (
      <>
        <Button
          onClick={() => navigate(`/management/content/add/`)}
          sx={{ my: 2, color: 'white', display: 'block' }}
          onMouseOver={handleOpenManagementMenu}
        >
          <Typography sx={{ typography: 'subtitle1' }}>管理</Typography>
        </Button>
        <Button
          onClick={handleLogoutBtnClick}
          sx={{ my: 2, color: 'white', display: 'block' }}
        >
          <Typography sx={{ typography: 'subtitle1' }}>登出</Typography>
        </Button>
      </>
    )
      : <Button
        onClick={props.handleLoginBtnClick}
        sx={{ my: 2, color: 'white', display: 'block' }}
      >
        <Typography sx={{ typography: 'subtitle1' }}>登入</Typography>
      </Button>;
  }

  React.useEffect(() => {
    if (auth.userDetails || auth.user) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth]);

  return (

    <Root
      value={location.pathname.replace(/(.*)\/[0-9]*?$/, "$1")}
      // showLabels={true}
      classes={{ root: classes.root }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
              disableScrollLock={true}
            >
              <MenuItem onClick={() => { navigate(`/district`); handleCloseNavMenu() }}>
                <Typography textAlign="center">十八區點泊好?</Typography>
              </MenuItem>
              <MenuItem onClick={() => { navigate(`/category/promotion`); handleCloseNavMenu() }}>
                <Typography textAlign="center">泊車優惠?</Typography>
              </MenuItem>
              <MenuItem onClick={() => { props.handleLoginBtnClick(); handleCloseNavMenu() }}>
                <Typography textAlign="center">登入</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 1 }} // display: { xs: 'flex', md: 'none' }
          >
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar variant="square" alt="parking-lot" src="img/parking-lot.png" />
            </IconButton>
          </Typography>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: { xs: 1, md: 0 } }} // mr: 2, display: { xs: 'none', md: 'flex' }
          >
            車迷會
          </Typography>


          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button
              onClick={() => navigate(`/home`)}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              <Typography sx={{ typography: 'subtitle1' }}>
                <HomeIcon fontSize="medium" />主頁
              </Typography>
            </Button>
            <Button
              onClick={() => navigate(`/district`)}
              // onMouseOver={handleOpenTestMenu}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              <Typography sx={{ typography: 'subtitle1' }}>
                <BeachAccessIcon fontSize="medium" />十八區點泊好？
              </Typography>
            </Button>
            <Button
              onClick={() => { navigate(`/category/promotion`); navigate(0); }}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              <Typography sx={{ typography: 'subtitle1' }}>
                <DiscountIcon fontSize="medium" />泊車優惠
              </Typography>
            </Button>
            <Button
              onClick={() => navigate(`/drivingInfo/`)}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              
              <Typography sx={{ typography: 'subtitle1' }}>
                <LocalGasStationIcon fontSize="medium" />油價資訊
              </Typography>
            </Button>
            <Button
              onClick={() => navigate(`/car-park-info/`)}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              <Typography sx={{ typography: 'subtitle1' }}>
                <LocalParkingIcon fontSize="medium" />即時車位數量
              </Typography>
            </Button>
            <Button
              onClick={() => navigate(`/map/`)}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              <Typography sx={{ typography: 'subtitle1' }}>
                <MapIcon fontSize="medium" />地圖模式
              </Typography>
            </Button>
            <Button
              onClick={() => navigate(`/settings/`)}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              <Typography sx={{ typography: 'subtitle1' }}>
                <SettingsIcon fontSize="medium" />設定
              </Typography>
            </Button>
            {loggedIn ? (
              <>
                <Button
                  onClick={() => navigate(`/management/content/add/`)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  onMouseOver={handleOpenManagementMenu}
                >
                  <Typography sx={{ typography: 'subtitle1' }}>管理</Typography>
                </Button>
                <Button
                  onClick={handleLogoutBtnClick}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  <Typography sx={{ typography: 'subtitle1' }}>登出</Typography>
                </Button>
              </>
            )
              : <Button
                onClick={props.handleLoginBtnClick}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                <Typography sx={{ typography: 'subtitle1' }}>登入</Typography>
              </Button>
            }

            <Menu
              id="menu-tet"
              anchorEl={anchorElTest}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElTest)}
              onClose={handleCloseTestMenu}
              disableScrollLock={true}
              MenuListProps={{ onMouseLeave: handleCloseTestMenu }}
            >
              <Paper sx={{ width: 320, maxWidth: '100%' }}>
                <MenuList>
                  <MenuItem>
                    <ListItemIcon>
                      <HomeIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>首頁</ListItemText>
                    {/* <Typography variant="body2" color="text.secondary">
                      ⌘X
                    </Typography> */}
                  </MenuItem>
                  <MenuItem>
                    <ListItemIcon>
                      <DirectionsCarIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>駕駛資訊</ListItemText>
                    {/* <Typography variant="body2" color="text.secondary">
                      ⌘C
                    </Typography> */}
                  </MenuItem>
                  <MenuItem>
                    <ListItemIcon>
                      <LocalParkingIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>實時泊位</ListItemText>
                    {/* <Typography variant="body2" color="text.secondary">
                      ⌘V
                    </Typography> */}
                  </MenuItem>
                  <MenuItem>
                    <ListItemIcon>
                      <MapIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>地圖模式</ListItemText>
                    {/* <Typography variant="body2" color="text.secondary">
                      ⌘V
                    </Typography> */}
                  </MenuItem>
                  <MenuItem>
                    <ListItemIcon>
                      <SettingsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>設定</ListItemText>
                    {/* <Typography variant="body2" color="text.secondary">
                      ⌘V
                    </Typography> */}
                  </MenuItem>
                  {/* <Divider />
                  <MenuItem>
                    <ListItemIcon>
                      <Cloud fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Web Clipboard</ListItemText>
                  </MenuItem> */}
                </MenuList>
              </Paper>
            </Menu>
            <Menu
              id="menu-management"
              anchorEl={anchorElManagement}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElManagement)}
              onClose={handleCloseManagementMenu}
              disableScrollLock={true}
              MenuListProps={{ onMouseLeave: handleCloseManagementMenu }}
            >
              <Paper sx={{ width: 320, maxWidth: '100%' }}>
                <MenuList>
                  <MenuItem onClick={() => navigate(`/management/content/add/`)}>
                    <ListItemIcon>
                      <HomeIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>新增</ListItemText>
                    {/* <Typography variant="body2" color="text.secondary">
                      ⌘X
                    </Typography> */}
                  </MenuItem>
                  <MenuItem onClick={() => navigate(`/management/edit/`)}>
                    <ListItemIcon>
                      <DirectionsCarIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>更改現有內容</ListItemText>
                    {/* <Typography variant="body2" color="text.secondary">
                      ⌘C
                    </Typography> */}
                  </MenuItem>
                </MenuList>
              </Paper>
            </Menu>
          </Box>


          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="車迷會">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="車迷會" src="p.png" />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </Root>
  );
};

const PREFIX = "header";

const classes = {
  root: `${PREFIX}-root`,
  selected: `${PREFIX}-selected`,
};

const Root = styled(AppBar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    background:
      theme.palette.mode === "dark"
        ? theme.palette.background.default
        : "#f3c622",
    position: "sticky",
    width: "100%",
    bottom: "0",
    height: "initial",
  },
}));

export default Header;
