import { useState, useEffect, forwardRef } from "react";
// React Router
import { useNavigate } from "react-router-dom";

import CarParkInfoList from "../components/carpark/CarParkInfoList";
import { CircularProgress, Container, Select, MenuItem, Dialog, FormControl, Slide, Typography, InputLabel, Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import "../index.css";
import classesLayout from '../components/layout/Layout.module.css';
import CarParkDetail from "../components/carpark/CarParkDetail";
import CarparkMap from "./CarparkMap";

// proxy
// https://stackoverflow.com/questions/65427633/fetch-data-from-local-api

// hardcode before backend ready
const districtsMap = new Map([
    ["中西區", { name: "中西區", symbol: "C&W", leftLongtitude: "114.126338", bottomLatitude: "22.278530", rightLongtitude: "114.164432", topLatitude: "22.288529" }],
    ["灣仔區", { name: "灣仔區", symbol: "WC", leftLongtitude: "114.16596651363112", bottomLatitude: "22.27115677337877", rightLongtitude: "114.19280224336029", topLatitude: "22.284919691684728" }],
    ["東區", { name: "東區", symbol: "E", leftLongtitude: "114.19176298481578", bottomLatitude: "22.26204691602658", rightLongtitude: "114.2542301055486", topLatitude: "22.29869345676196" }],
    ["南區", { name: "南區", symbol: "S", leftLongtitude: "114.11864333217984", bottomLatitude: "22.208293077704116", rightLongtitude: "114.25484044181708", topLatitude: "22.26017970219959" }],
    ["荃灣區", { name: "荃灣區", symbol: "K&T", leftLongtitude: "114.10737812475249", bottomLatitude: "22.365341890583192", rightLongtitude: "114.12145435811343", topLatitude: "22.37439025435588" }],
    ["油尖旺區", { name: "油尖旺區", symbol: "YTM", leftLongtitude: "114.14998916320998", bottomLatitude: "22.290891733423006", rightLongtitude: "114.19668105798866", topLatitude: "22.329800428987205" }],
    ["深水埗區", { name: "深水埗區", symbol: "SSP", leftLongtitude: "114.13316398488058", bottomLatitude: "22.320903596797045", rightLongtitude: "114.16787008865349", topLatitude: "22.345019877963566" }],
    ["九龍城區", { name: "九龍城區", symbol: "KC", leftLongtitude: "114.16983369233981", bottomLatitude: "22.326192512354755", rightLongtitude: "114.20146287407385", topLatitude: "22.34271951174014" }],
    ["黃大仙區", { name: "黃大仙區", symbol: "WTS", leftLongtitude: "114.18789997813569", bottomLatitude: "22.335945989388332", rightLongtitude: "114.20933728824363", topLatitude: "22.35159535627937" }],
    ["觀塘區", { name: "觀塘區", symbol: "KT", leftLongtitude: "114.20226462092513", bottomLatitude: "22.284135628331047", rightLongtitude: "114.24452974573346", topLatitude: "22.336501844258372" }],
    ["荃灣區", { name: "荃灣區", symbol: "TW", leftLongtitude: "114.08084644947617", bottomLatitude: "22.32389185284509", rightLongtitude: "114.14467422533052", topLatitude: "22.383379068655195" }],
    ["屯門區", { name: "屯門區", symbol: "TM", leftLongtitude: "113.96230915240113", bottomLatitude: "22.349877899198397", rightLongtitude: "114.03321526697977", topLatitude: "22.417990841771612" }],
    ["元朗區", { name: "元朗區", symbol: "YL", leftLongtitude: "113.9892447977211", bottomLatitude: "22.408156431901734", rightLongtitude: "114.06772972104504", topLatitude: "22.48724905913926" }],
    ["北區", { name: "北區", symbol: "N", leftLongtitude: "114.06702051958197", bottomLatitude: "22.45579220267072", rightLongtitude: "114.21760755642913", topLatitude: "22.54621139633088" }],
    ["大埔區", { name: "大埔區", symbol: "TP", leftLongtitude: "114.13226702203553", bottomLatitude: "22.434379980449087", rightLongtitude: "114.24526639986823", topLatitude: "22.48397263714704" }],
    ["西貢區", { name: "西貢區", symbol: "SK", leftLongtitude: "114.25070360794905", bottomLatitude: "22.274339438543386", rightLongtitude: "114.38756388314951", topLatitude: "22.483063013324934" }],
    ["沙田區", { name: "沙田區", symbol: "ST", leftLongtitude: "114.16132986348278", bottomLatitude: "22.356376059178128", rightLongtitude: "114.25617412557384", topLatitude: "22.441248991926372" }],
    ["荃灣區", { name: "荃灣區", symbol: "K&T", leftLongtitude: "114.08084644947617", bottomLatitude: "22.32389185284509", rightLongtitude: "114.14467422533052", topLatitude: "22.383379068655195" }],
    ["離島區", { name: "離島區", symbol: "IS", leftLongtitude: "113.8294930329234", bottomLatitude: "22.182275423734595", rightLongtitude: "114.07325043769275", topLatitude: "22.35814718847347" }]
]);

function CarParkInfoPage() {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [loadedCarParks, setLoadedCarParks] = useState([]);
    const [open, setOpen] = useState(false);
    const [carparkDetail, setCarparkDetail] = useState([]);

    const [district, setDistrict] = useState("中西區"); // hardcode chinese name before backend ready

    useEffect(() => {
        let extent = districtsMap.get(district).leftLongtitude + "," + districtsMap.get(district).bottomLatitude + ","
            + districtsMap.get(district).rightLongtitude + "," + districtsMap.get(district).topLatitude;

        const urls = [
            `https://api.data.gov.hk/v1/carpark-info-vacancy?data=info&extent=` + extent + `&lang=zh_TW`,
            `https://api.data.gov.hk/v1/carpark-info-vacancy?data=vacancy&extent=` + extent + `&lang=zh_TW`
        ]
        try {
            let carparkInfo, carparkVacancy;
            const getCarParkData = async () => {
                [carparkInfo, carparkVacancy] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);

                let infoArray = carparkInfo['results'];
                let vacancyArray = carparkVacancy['results'];

                var res = vacancyArray.map(x => Object.assign(x, infoArray.find(y => y.park_Id === x.park_Id)));
                res = res.filter((carpark) => carpark.district === districtsMap.get(district).name);

                // console.log('res: ' + JSON.stringify(res));

                var item = [];
                var items = [];

                for (var i = 0; i < res.length; i++) {
                    // console.log(carpark[i]);

                    item.push(res[i]);
                    // console.log(item);
                    if (i > 0 && ((i + 1) % 3 === 0 || i >= res.length - 1)) {
                        items.push({ list: item });
                        item = [];
                    }
                }


                setLoadedCarParks(items);
            }
            getCarParkData();

        } catch (error) {
            console.log(error);
        }

    }, [district]);

    if (isLoading) {
        return (
            <Root className={classes.loading}>
                <div className='loading-center'>
                    <div style={{ textAlign: 'center', marginBottom: '6rem' }}>載入中</div>
                    <div className='loading-center'>
                        <CircularProgress />
                    </div>
                </div>
            </Root>
        )
    }

    function onChangeDistrictHandler(e) {
        setIsLoading(true);
        setDistrict(e.target.value);
    }

    const toCarparkDetailPage = (carparkId) => {
        console.log("toCarparkDetailPage");
        // TODO:
        navigate(`/car-park-detail/` + carparkId);
        // navigate(0); // force to reload page
    }

    const handleOpen = (carparkDetail) => {
        console.log("handleOpen");
        setOpen(true);
        setCarparkDetail(carparkDetail);
    }

    const openGoogleMap = (address) => {
        window.open('https://www.google.com/maps/search/?api=1&query=' + address);
    }

    const handleClose = () => {
        console.log("handleClose");
        setOpen(false);
    }

    return (
        <RootBox>
            <Root className={classes.root}>
                <Box sx={{ p: 1 }}>
                    <Typography variant="h4">停車場資料</Typography>
                </Box>
                <FormControl sx={{ width: '100%' }} size="small">
                    <InputLabel id="demo-select-small">地區</InputLabel>
                    <Select
                        label="地區"
                        value={district}
                        onChange={e => { onChangeDistrictHandler(e) }}
                    >
                        {Array.from(districtsMap.values()).map(d => (
                            <MenuItem key={d.name} value={d.name}>{d.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box sx={{ pb: 7 }}>
                        <Grid item
                            container
                            spacing={0}
                            direction="row"
                            justifyContent="center"
                        >
                            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <CarParkInfoList carparks={loadedCarParks} handleOpen={handleOpen} openGoogleMap={openGoogleMap} toCarparkDetailPage={toCarparkDetailPage}/>
                                </Grid>
                                {/* <Grid item xs={12} sm={12} md={6}>
                                    <Box mt={2}>
                                        <CarparkMap />
                                    </Box>
                                </Grid> */}
                            </Grid>
                        </Grid>
                </Box>
                <DialogItem
                    fullScreen
                    open={open}
                    // classes={{ root: classes.root }}
                    className={classes.root}
                    // onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <CarParkDetail handleClose={handleClose} carparkDetail={carparkDetail} />
                </DialogItem>
            </Root>
        </RootBox>
    )
}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PREFIX = "carparkInfo";

const classes = {
    root: `${PREFIX}-root`,
    selected: `${PREFIX}-selected`,
    loading: `${PREFIX}-loading`,
};

const DialogItem = styled(Dialog)(({ theme }) => ({
    [`&.${classes.root}`]: {
        top: `30vh !important`,
        // bottom: `64px !important`,
    },
}));

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        height: '100%',
        backgroundColor: theme.palette.mode === 'dark'
            ? '#1A2027'
            : '#fff',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '90vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark' 
        ? '#1A2027' 
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

export default CarParkInfoPage;
