import ParkBinItem from './ParkBinItem';
import Box from '@mui/material/Box';
import { timeSince } from '../../util/TimeUtil';

function ParkBinList({carparks}) {
    return (
        <Box mt={2}>
            {carparks.map((carpark, index) => (
                <>
                <ParkBinItem
                    key={carpark.id}
                    id={carpark.parkbinId}
                    index={index + 1}
                    name={carpark.carparkName}
                    description={carpark.description}
                    privateCarVacancy={(!carpark.hasOwnProperty('privateCar') || carpark.privateCar[0] === undefined) ? 'N/A' : carpark.privateCar[0]['vacancy']}
                    privateCarLastUpdate={(!carpark.hasOwnProperty('privateCar') || carpark.privateCar[0] === undefined) ? 'N/A' : timeSince(Date.parse(carpark.privateCar[0]['lastupdate']))}
                    latitude={carpark.latitude}
                    longitude={carpark.longitude}
                />
                </>
            ))}
        </Box>
    );
}

export default ParkBinList;