import React, { useState, useEffect, forwardRef, Fragment } from "react";
import { useParams } from 'react-router-dom';

import { CircularProgress, Container, Slide, Box, Divider, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import "../index.css";
import SocialMediaBar from "../components/SocialMedia"
import CarParkImageList from "../components/carpark/CarParkImageList";
import { toDateString } from '../util/TimeUtil';
import Leaflet from 'leaflet';
import { Map, TileLayer, Marker, Popup, MapContainer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import { API_URL } from '../environment';

// proxy
// https://stackoverflow.com/questions/65427633/fetch-data-from-local-api

function PromotionPage(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [promotionDetail, setPrmotionDetail] = useState([]);
    const [open, setOpen] = useState(false);
    const { Id, type } = useParams();

    useEffect(() => {
        const urls = [
            API_URL + `/api/promotion?Id=` + Id
        ]
        try {
            let promotionDetail;
            const getPromotionData = async () => {
                [promotionDetail] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);
                setPrmotionDetail(promotionDetail);

            }

            getPromotionData();

        } catch (error) {
            console.log(error);
        }

    }, []);

    if (isLoading) {
        return (
            <Root className={classes.loading}>
                <div className='loading-center'>
                    <div style={{ textAlign: 'center', marginBottom: '6rem' }}>載入中</div>
                    <div className='loading-center'>
                        <CircularProgress />
                    </div>
                </div>
            </Root>
        )
    }

    if (type === 'promotion') {
        return (
            <RootBox>
                <Root className={classes.root}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={7}>
                            <MainContent promotionDetail={promotionDetail} />
                            <OpenStreetMap promotionDetail={promotionDetail} />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <CarParkImageList.ParkingDiscount />
                            <CarParkImageList.ParkBin />
                        </Grid>

                    </Grid>
                </Root>
            </RootBox>
        )
    }

}

const OpenStreetMap = (props) => {
    const center = [props.promotionDetail.latitude, props.promotionDetail.longitude];
    const carparkIcon = Leaflet.icon({
        iconUrl: '/marker-icon.png',
        iconSize: [48, 48],
        iconAnchor: [32, 64],
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });

    return (
        <Box sx={{ m: 1 }}>
            <Box sx={{ background: "rgb(246, 255, 115)" }}>
                <Typography sx={{ typography: { sm: 'subtitle1', xs: 'subtitle1', md: 'h6' } }}>{props.promotionDetail.carparkName}</Typography>

            </Box>
            <MapContainer center={center} zoom={17} scrollWheelZoom={false} style={{ height: "50vh", width: "100%", zIndex: 0 }} >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker icon={carparkIcon} position={center}>
                    <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                </Marker>
            </MapContainer>
        </Box>
    )
}

const MainContent = (props) => {
    return (
        <>
            <Box sx={{ p: 1 }}>
                <Typography sx={{ typography: { sm: 'h4', xs: 'h4', md: 'h3' } }}>{props.promotionDetail.title}</Typography>
                <Typography sx={{ typography: { sm: 'subtitle2', xs: 'subtitle2', md: 'subtitle1' } }}>{toDateString(new Date(props.promotionDetail.createdDate))}</Typography>
            </Box>
            <SocialMediaBar url={window.location.href} title={props.promotionDetail.title} />
            <img src={props.promotionDetail.image} width={`100%`} />
            <Box sx={{ p: 1 }}>
                <Typography component={'span'} variant="body1"><div dangerouslySetInnerHTML={{ __html: props.promotionDetail.content }}></div></Typography>
            </Box>
            <Divider variant="middle" />
            <SocialMediaBar url={window.location.href} title={props.promotionDetail.title} />
        </>
    )
}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PREFIX = "carparkInfo";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        paddingBottom: '50px',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '100vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

export default PromotionPage;