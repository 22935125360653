import { useContext, useState } from "react";

import FavoritesContext from "../../store/favorites-context";
import { Paper, Card, CardMedia, CardContent, CardActions, Typography, Box, Grid, Button, Divider, Stack } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DirectionsIcon from '@mui/icons-material/Directions';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { GoogleMap, useJsApiLoader, StreetViewPanorama, LoadScript } from '@react-google-maps/api';
import { Key } from '../../googleMapKey';
import classesLayout from '../layout/Layout.module.css';


function ParkBinItem(props) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Key
    })

    const center = {
        lat: props.latitude,
        lng: props.longitude
    }
    
    return isLoaded ? (
        <>
            <Box sx={{p: 1}}>
                <Typography variant="body1">{props.index}. {props.name}</Typography>
                <Typography variant="body1">{props.description}</Typography>
            </Box>
            <Card>
                <div className={classesLayout.streetview}>
                    <GoogleMap
                        mapContainerStyle={{
                            width: '100%',
                            height: '100%'
                        }}
                        // onLoad={onLoad}
                        center={center}
                        zoom={7}
                    // options={defaultMapOptions}
                    >
                        <StreetViewPanorama
                        position={center}
                        visible={true}
                        />
                    </GoogleMap>
                </div>
                <CardActions>
                    <Button variant="contained" endIcon={<DirectionsIcon />}>
                        前往
                    </Button >
                    <Button variant="contained" endIcon={<FavoriteIcon />}>
                        賣飛佛
                    </Button>

                    {props.privateCarVacancy > 0
                        ? <Paper elevation={3} className="ms-auto" style={{ backgroundColor: '#66bb6a', padding: '5px 5px 5px 5px' }}>
                            <Typography variant="h4" color="text.primary" style={{ textAlign: 'center' }}>
                                {props.privateCarVacancy}
                            </Typography>
                            <Divider />
                            <Typography variant="body2" color="text.primary" style={{ textAlign: 'center' }}>
                                {props.privateCarLastUpdate}
                            </Typography>
                        </Paper>
                        :
                        <Paper elevation={3} className="ms-auto" style={{ backgroundColor: '#ef5350', padding: '5px 5px 5px 5px' }}>
                            <Typography variant="h4" color="text.primary" style={{ textAlign: 'center' }}>
                                {props.privateCarVacancy}
                            </Typography>
                            <Divider />
                            <Typography variant="body2" color="text.primary" style={{ textAlign: 'center' }}>
                                {props.privateCarLastUpdate}
                            </Typography>
                        </Paper>
                    }

                </CardActions>
            </Card>
            <Divider variant="middle" sx={{ mt: 2 }} />
        </>
    ) : <></>;
}

export default ParkBinItem;