import React, { useState, useEffect } from "react";

import CarParkInfoList from "../components/carpark/CarParkInfoList";
import CarParkImageList from "../components/carpark/CarParkImageList";
import { Box, Container, Divider, Typography, Grid, Stack } from "@mui/material";
import { styled } from '@mui/material/styles';
import "../index.css";
import classesLayout from '../components/layout/Layout.module.css';
import Fader from '../components/layout/Fader'
import '../components/layout/Layout.module.css';

function HomePage() {
    // https://www.npmjs.com/package/medium-zoom
    // https://www.react-fast-marquee.com/documentation
    return (
        <RootBox>
            <Root className={classes.root} sx={{ mb: 4 }}>
                <Stack direction="row">
                    <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Grid item xs={3} sm={3} md={3}>
                            <Box sx={{ mt: 1, mb: 1, width: {xs: '80px', md: '100px'} , height: '30px', background: '#f3c622' }}>
                                <Typography align='center' sx={{ typography: { sm: 'subtitle1', xs: 'subtitle1', md: 'h6' }, mb: 1 }}>
                                    最新消息
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={9} sm={9} md={9}>
                            <Box sx={{ mt: 1, mb: 1 }}>
                                <Fader />
                            </Box>
                        </Grid>
                    </Grid>
                </Stack>
                <Title title={"出街泊邊好？"} />
                <CustomDivider className={'ParkBin'} />
                <SubTitle subtitle={"出街泊車唔洗頭㾗"} />
                <Box sx={{ mt: 1 }} >
                    <CarParkImageList.Banner />
                </Box>
                <Title title={"熱門停車場"} />
                <CustomDivider className={'ParkBin'} />
                <SubTitle subtitle={"睇睇香港最多人最熱門泊車熱點"} />
                <Box sx={{ mt: 1 }} >
                    <CarParkImageList.HotCarparkList />
                </Box>
                {/* <Box sx={{ mt: 1 }} >
                    <CarParkImageList.Banner />
                </Box> */}
                <br />
                <Title title={"最新優惠"} />
                <CustomDivider className={'ParkingDiscount'} />
                <SubTitle subtitle={"泊車優惠及入油優惠"} />
                <CarParkImageList.Discount />
                <br />
                <Title title={"最受歡迎活動及優惠"} />
                <CustomDivider className={'Event'} />
                <Box sx={{ mt: 1, pb: 2 }} >
                    <CarParkImageList.Standard />
                </Box>
            </Root>
        </RootBox>
    )
}

const PREFIX = "home";

const classes = {
    root: `${PREFIX}-root`,
};

const Title = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                >
                    <Typography
                        variant="h4"
                        component="div"
                        sx={{fontWeight: 'bold'}} 
                        // sx={{ flexGrow: { xs: 1, md: 0 } }} // mr: 2, display: { xs: 'none', md: 'flex' }
                    >
                        {props.title}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const SubTitle = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                >
                    <Typography
                        variant="subtitle1"
                        component="div"
                        // sx={{ flexGrow: { xs: 1, md: 0 } }} // mr: 2, display: { xs: 'none', md: 'flex' }
                    >
                        {props.subtitle}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,

    },
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
    // [`&.MuiDivider-root`]: {
    //     background:
    //         theme.palette.mode === "dark"
    //             ? theme.palette.primary.main
    //             : theme.palette.action.active,
    //     borderBottomWidth: 2, 
    // },
    [`&.ParkingDiscount`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : theme.palette.primary.main,
        borderBottomWidth: 6,
        marginLeft: '30%',
        marginRight: '30%'
    },
    [`&.ParkBin`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : "#F38181",
        borderBottomWidth: 6,
        marginLeft: '30%',
        marginRight: '30%'
    },
    [`&.Event`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : "#95E1D3",
        borderBottomWidth: 6,
    },
    [`&.ParkBinTest`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : theme.palette.primary.main,
        borderBottomWidth: 3,
        [theme.breakpoints.up('xs')]: {
            marginLeft: '3em',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '5em',
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: '6em',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '4.5em',
        },
        // marginBottom: 20
    },

}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
        paddingLeft: '5rem',
        paddingRight: '5rem',
    },
}));

export default HomePage;