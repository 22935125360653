import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  HashRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { AppContextProvider } from './store/app-context';
import ScrollToTop from './util/ScrollToTop';
import Helmet from 'helmet';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  }
})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppContextProvider>
      <Router>
        <Helmet>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8425479417166969"
      crossorigin="anonymous"></script>
        </Helmet>
        <ScrollToTop />
        <App />
      </Router>
  </AppContextProvider>
);
