import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DirectionsIcon from '@mui/icons-material/Directions';
import InfoIcon from '@mui/icons-material/Info';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import { Box, Card, CardMedia, CardContent, CardActions, DialogTitle, Grid, Stack, Paper } from '@mui/material';

const MarkerDetail = (props) => {
    var remarkItem = "abc"
    remarkItem = remarkItem.split('<br>');
    
    return (
        <>
            <AppBar sx={{ position: 'relative', height: '0px' }}>
                <Toolbar>
                    <IconButton
                        size="medium"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        // sx={{ mr: 2 }}
                    >
                        <LocalParkingIcon />
                    </IconButton>
                    <Typography sx={{ flex: 1 }} variant="h6" component="div">
                        資料
                    </Typography>
                    <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-haspopup="true"
                            onClick={() => props.handleClose()}
                            color="inherit"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Card sx={{height: '100%'}}>
                <CardMedia
                    component="img"
                    height="50"
                    image={`https://oil-price.consumer.org.hk/assets/shared/img/bg_section-discount-mobile.jpg`}
                />
                 <CardContent>
                    <Box>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={8} md={9}>
                                <Typography gutterBottom variant="h5" component="div">
                                    {props.markerDetail.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {props.markerDetail.address}
                                </Typography>
                                <Typography variant="h5" color="text.secondary">
                                    {props.markerDetail.hasOwnProperty('vehicleType') ?  
                                        props.markerDetail.vehicleType === 'A' ? `私家車` : 
                                        (props.markerDetail.vehicleType === 'C' ? `旅遊巴` : (props.markerDetail.vehicleType === 'G' ? `貨車` : ''))  : ""}
                                </Typography>
                                <Typography variant="h5" color="text.secondary">
                                    {props.markerDetail.hasOwnProperty('timeUnit') ? `咪錶` + props.markerDetail.timeUnit + `分鐘` : ""}
                                </Typography>
                                <Typography variant="h5" color="text.secondary">
                                    {props.markerDetail.regionTC}{props.markerDetail.districtTc}{props.markerDetail.subDistrictTc}{props.markerDetail.streetTc}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <Stack direction="horizontal" gap={1}>
                                    {/* <Paper elevation={3} className="ms-auto" style={{ backgroundColor: '#66bb6a', padding: '5px 5px 5px 5px' }}> */}
                                        {props.privateCarVacancy > 0
                                            ? <Paper elevation={3} className="ms-auto" style={{ backgroundColor: '#66bb6a', padding: '5px 5px 5px 5px' }}>
                                                <Typography variant="h4" color="text.primary" style={{ textAlign: 'center' }}>
                                                    {1}
                                                </Typography>
                                                <Divider />
                                                <Typography variant="body2" color="text.primary" style={{ textAlign: 'center' }}>
                                                    1分鐘前
                                                </Typography>
                                            </Paper>
                                            :
                                            <Paper elevation={3} className="ms-auto" style={{ backgroundColor: '#ef5350', padding: '5px 5px 5px 5px' }}>
                                                <Typography variant="h4" color="text.primary" style={{ textAlign: 'center' }}>
                                                    {1}
                                                </Typography>
                                                <Divider />
                                                <Typography variant="body2" color="text.primary" style={{ textAlign: 'center' }}>
                                                    1分鐘前
                                                </Typography>
                                            </Paper>
                                        }
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
                <CardActions>
                    <Button variant="contained" endIcon={<InfoIcon />}>
                        詳情
                    </Button>
                    <Button variant="contained" endIcon={<DirectionsIcon />} onClick={() => props.openGoogleMap(props.markerDetail.address)}>
                        前往
                    </Button >
                </CardActions>
            </Card>
        </>
    );
}

export default MarkerDetail;