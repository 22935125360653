import { useState, useEffect, forwardRef, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// import classes from "../meetups/NewMeetupForm.module.css";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import classes from "../meetups/NewMeetupForm.module.css";
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "../../index.css";

// Hooks
import { useRef } from "react";

const Input = styled('input')({
    display: 'none',
});

const districtsMap = new Map([
    ["中西區", { id: "9", name: "中西區"}],
    ["灣仔區", { id: "3", name: "灣仔區"}],
    ["東區", { id: "8", name: "東區"}],
    ["南區", { id: "1", name: "南區"}],
    ["荃灣區", { id: "10", name: "荃灣區"}],
    ["油尖旺區", { id: "18", name: "油尖旺區"}],
    ["深水埗區", { id: "2", name: "深水埗區"}],
    ["九龍城區", { id: "7", name: "九龍城區"}],
    ["黃大仙區", { id: "5", name: "黃大仙區"}],
    ["觀塘區", { id: "6", name: "觀塘區"}],
    ["葵青區", { id: "11", name: "葵青區"}],
    ["屯門區", { id: "13", name: "屯門區"}],
    ["元朗區", { id: "14", name: "元朗區"}],
    ["北區", {id: "15",  name: "北區"}],
    ["大埔區", { id: "16", name: "大埔區"}],
    ["西貢區", { id: "17", name: "西貢區"}],
    ["沙田區", { id: "4", name: "沙田區"}],
    ["離島區", { id: "12", name: "離島區"}]
]);

function EditContentForm(props) {
    const titleInputRef = useRef();
    const districtInputRef = useRef();
    const imageInputRef = useRef();
    const contentInputRef = useRef();
    const carparkIdInputRef = useRef();
    const iframeRef = useRef();

    const [message, setMessage] = useState('');

    function submitHandler(event) {
        console.log('type: ' + props.contentDetail.category);
        event.preventDefault();

        const enteredTitle = titleInputRef.current.value;
        // const enteredDistrict = districtInputRef.current.value;
        // const enteredImage = imageInputRef.current.value;
        // const imageFile = document.getElementsByClassName('input-image')[0].files[0];
        const enteredContent = contentInputRef.current.value;
        const enteredDistrict = districtInputRef.current.value;
        const enteredCarparkId = carparkIdInputRef.current.value;
        const createdDate = props.contentDetail.createdDate !== undefined ? props.contentDetail.createdDate : new Date();
        const lastModifiedDate = new Date();

        const contentData = props.contentDetail.category == 'promotion' ? {
            id: props.contentDetail.id,
            title: enteredTitle,
            districtId: enteredDistrict,
            image: props.contentDetail.image,
            content: enteredContent,
            carparkId: enteredCarparkId,
            createdDate: createdDate,
            lastModifiedDate: lastModifiedDate,
            // imageFile: imageFile,
            category: props.contentDetail.category
        } : props.contentDetail.category == 'parkbin' ? 
        {
            id: props.contentDetail.id,
            title: enteredTitle,
            districtId: enteredDistrict,
            photoBanner: props.contentDetail.image,
            content: enteredContent,
            carparkId: enteredCarparkId,
            createdDate: createdDate,
            lastModifiedDate: lastModifiedDate,
            // imageFile: imageFile,
            category: props.contentDetail.category
        } : {};

        console.log(contentData);

        props.editContent(contentData);
    }

    function uploadImageHandler(event) {
        event.preventDefault();
        const imageFile = document.getElementsByClassName('input-image-2')[0].files[0];

        const imageData = {
            imageFile: imageFile
        }

        props.handleImageUpload(imageData);
    }

    useEffect(() => {
        contentInputRef.current.addEventListener('keyup', () => {
            var html = contentInputRef.current.value;
            setMessage("data:text/html;charset=utf-8," + encodeURI(html));
        })

        contentInputRef.current.addEventListener('change', () => {
            var html = contentInputRef.current.value;
            setMessage("data:text/html;charset=utf-8," + encodeURI(html));
        })
    }, [])

    return (
        <>
            <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="h4"
                color="text.primary"
            >
                更改文章內容
            </Typography>
            <form className={classes.form} onSubmit={submitHandler}>
                <div className={classes.control}>
                    <label htmlFor="title">標題</label>
                    <TextField hiddenLabel type="text" required id="title" defaultValue={props.contentDetail.title} inputRef={titleInputRef} sx={{width: '100%'}}/>
                </div>
                <div className={classes.control}>
                    <label htmlFor="title">地區</label>
                    <FormControl sx={{ width: '100%' }} size="small">
                        <Select
                            inputRef={districtInputRef}
                            defaultValue={props.contentDetail.districtId}
                        >
                            {Array.from(districtsMap.values()).map(d => (
                                <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                {/* <div className={classes.control}>
                    <label htmlFor="image">圖片</label>
                    <input type="file" className="input-image" required id="image" ref={imageInputRef} />
                </div> */}
                <Grid container spacing={2} sx={{mb : 2}}>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.control}>
                            <label htmlFor="address">內容</label>
                        </div>
                        <Stack direction="row" alignItems="center" spacing={2}>
                        <label htmlFor="icon-button-file">
                            <Input accept="image/*" className="input-image-2" id="icon-button-file" type="file" onChange={uploadImageHandler}/>
                            <IconButton color="primary" aria-label="upload picture" component="span">
                            <PhotoCamera />
                            </IconButton>
                        </label>
                        <label htmlFor="icon-button-bold">
                            <IconButton color="primary" aria-label="format bold" component="span" onClick={props.handleBoldBtn}>
                            <FormatBoldIcon />
                            </IconButton>
                        </label>
                        <label htmlFor="icon-button-itlaic">
                            <IconButton color="primary" aria-label="format italic" component="span" onClick={props.handleItalicBtn}>
                            <FormatItalicIcon />
                            </IconButton>
                        </label>
                        <label htmlFor="icon-button-underlined">
                            <IconButton color="primary" aria-label="format underlined" component="span" onClick={props.handleUnderlinedBtn}>
                            <FormatUnderlinedIcon />
                            </IconButton>
                        </label>
                        </Stack>
                            <TextField multiline rows={12} id="content" required defaultValue={props.contentDetail.content} inputRef={contentInputRef} sx={{width: '100%'}}/>
                        
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <div className={classes.control} style={{height:'100%'}}>
                            <label htmlFor="address">預覽</label>
                            <iframe src={message} ref={iframeRef} style={{width: '100%', height: '85%' , backgroundColor: 'white'}}></iframe>
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.control}>
                    <label htmlFor="title">停車場編號</label>
                    <FormControl sx={{ width: '100%' }} size="small">
                        <Select
                            inputRef={carparkIdInputRef}
                            defaultValue={props.contentDetail.carparkId}
                        >
                            {props.contents.map(d => (
                                <MenuItem key={d.carparkId} value={d.carparkId}>{d.carparkId}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.actions}>
                    <button>確定</button>
                </div>
            </form>
        </>
    )
}

export default EditContentForm;