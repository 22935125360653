import { useState, useEffect } from "react";

import MeetupList from "../components/meetups/MeetupList";
import classes from '../components/layout/Layout.module.css';


const DUMMY_DATA = [
    {
        id: "m1",
        title: "This is first meetup",
        image: "https://www.yata.hk/media/q3zjsfwy/app-banner_990x326-h-_cn_op.jpg?width=1048&height=336&mode=crop&format=png",
        address: "Meetupstreet 5, 12345 MeetupCity",
        description: "This is first"
    },
    {
        id: "m2",
        title: "This is second meetup",
        image: "https://www.yata.hk/media/33qhey5e/22-apr-%E6%B6%88%E8%B2%BB%E5%88%B8-promotion-smk-_990x326-cn-r.jpg?width=1048&height=336&mode=crop",
        address: "Meetupstreet 5, 12345 MeetupCity",
        description: "This is second"
    },
    {
        id: "m3",
        title: "This is third meetup",
        image: "https://www.yata.hk/media/spmdhnxa/aus-nz-food-tour_app_banner_990x326.jpg?width=1048&height=336&mode=crop",
        address: "Meetupstreet 5, 12345 MeetupCity",
        description: "This is third"
    }
]

function AllMeetupsPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [loadedMeetups, setLoadedMeetups] = useState([]);

    useEffect(() => {
        fetch(
            "https://react-meetup-example-536e1-default-rtdb.firebaseio.com/meetups.json",
            {
                headers: {
                    'Content-Type': 'application/json',
                  }
            }
        ).then((res) => {
            return res.json();
        }).then((data) => {
            const meetups = [];

            for (const key in data) {
                const meetup = {
                    id: key,
                    ...data[key],
                }

                meetups.push(meetup);
            }

            setIsLoading(false);
            setLoadedMeetups(meetups);
        });
    }, []);

    if (isLoading) {
        return (
            <section>
                <p>Loading</p>
            </section>
        )
    }

    return (
        <section className={classes.main}>
            <h1>All Meetups</h1>
            {/* <MeetupList meetups={DUMMY_DATA} /> */}
            <MeetupList meetups={loadedMeetups}/>
        </section>
    )
}

export default AllMeetupsPage;