import React, { useState, useEffect, forwardRef, Fragment } from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from "@mui/material/styles";
import "../index.css";
import { toDateString } from '../util/TimeUtil';
import { Key } from '../imgurKey';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { API_URL } from '../environment';

// React Router
import { useNavigate } from "react-router-dom";

// Components
import CarParkTable from '../components/management/CarParkTable';
import ContentTable from '../components/management/ContentTable';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function EditManagementPage() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const [openCopyDialog, setOpenCopyDialog] = React.useState(false);
    const [imgurUrl, setImgurUrl] = React.useState('');

    const CopyDialog = () => {
        return (
            <Dialog
                open={openCopyDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <Container>
                    <Box sx={{ mt: 5, mb: 5, mx: 5 }} >
                        <Typography variant="h6" gutterBottom component="div">
                            上傳成功
                        </Typography>
                        <TextField
                            disabled
                            hiddenLabel
                            id="imgur-url-text"
                            defaultValue={imgurUrl}
                            variant="filled"
                            size="small"
                        />
                        <label htmlFor="icon-content-copy">
                            <IconButton color="primary" aria-label="copy text" component="span" onClick={() => copyText()}>
                                <ContentCopyIcon />
                            </IconButton>
                        </label>
                    </Box>
                </Container>
            </Dialog>
        )
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setOpenCopyDialog(false);
    };

    const copyText = () => {
        var copyText = document.getElementById("imgur-url-text");
        console.log('copytext: ' + copyText.value);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        alert("Copied the text: " + copyText.value);
    }


    const handleImageUpload = (imageData) => {
        Swal.fire({
            title: '確認?',
            icon: 'info',
            html: "<img src='" + URL.createObjectURL(imageData.imageFile) + "' style='width:150px;'>",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '上傳',
            cancelButtonText: '取消',
            customClass: {
                container: 'swal2-container-custom',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const d = new FormData();
                d.append('image', imageData.imageFile);
                d.append('title', 'carpark');

                fetch(
                    "https://api.imgur.com/3/image/",
                    {
                        method: 'POST',
                        body: d,
                        headers: {
                            "Authorization": "Client-ID " + Key,
                        },
                    }
                )
                    .then(res => res.json())
                    .then((result) => {
                        Swal.fire({
                            title: '上傳成功',
                            icon: 'success',
                            customClass: {
                                container: 'swal2-container-custom',
                            }
                        });
                        document.getElementById("content").value += "<img src=\"" + result.data.link + "\" alt=\"" + result.data.title + "\" />";
                    })
            }
        })
    }

    const handleBoldBtn = () => {
        document.getElementById("content").value += "<b></b>";
    }

    const handleItalicBtn = () => {
        document.getElementById("content").value += "<i></i>";
    }

    const handleUnderlinedBtn = () => {
        document.getElementById("content").value += "<u></u>";
    }

    function addContentHandler(contentData) {
        console.log('addContentHandler...');

        const d = new FormData();
        d.append('image', contentData.imageFile);
        d.append('title', 'carpark');

        if (contentData.category === 'parkbin') {
            console.log('parkbin');
        } 

        if (contentData.category === 'promotion') {
            console.log('promotion');
        }

        // fetch(
        //     API_URL + "/api/content",
        //     {
        //         method: "POST",
        //         body: JSON.stringify(contentData),
        //         headers: {
        //             "Content-Type": "application/json",
        //         },
        //     }
        // ).then((res) => {
        //     if (res.status == '201') {
        //         Swal.fire({
        //             title: '完成!',
        //             text: '已加入',
        //             icon: 'success',
        //             confirmButtonText: '關閉'
        //         })
        //         navigate("/management/content/add");
        //     } else {
        //         Swal.fire({
        //             title: '失敗!',
        //             text: '發生未知的錯誤',
        //             icon: 'info',
        //             confirmButtonText: '關閉'
        //         })
        //     }
        // })


    }

    function addCarParkHandler(contentData) {
        console.log('addCarParkHandler...');

        const d = new FormData();
        d.append('image', contentData.carparkPhoto);
        d.append('title', 'carpark');

        fetch(
            "https://api.imgur.com/3/image/",
            {
                method: 'POST',
                body: d,
                headers: {
                    "Authorization": "Client-ID " + Key,
                },
            }
        )
            .then(res => res.json())
            .then((result) => {
                if (result.data.link) {
                    contentData.carparkPhoto = result.data.link;
                    fetch(
                        API_URL + "/api/carpark",
                        {
                            method: "POST",
                            body: JSON.stringify(contentData),
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    ).then((res) => {
                        if (res.status == '201') {
                            Swal.fire({
                                title: '完成!',
                                text: '已加入',
                                icon: 'success',
                                confirmButtonText: '關閉'
                            })
                            navigate("/management/content/add");
                        } else {
                            Swal.fire({
                                title: '失敗!',
                                text: '資料錯誤',
                                icon: 'info',
                                confirmButtonText: '關閉'
                            })
                        }

                    });
                } else {
                    Swal.fire({
                        title: '錯誤!',
                        text: '上傳相片失敗',
                        icon: 'error',
                        confirmButtonText: '關閉'
                    })
                }

            });


    }

    return (
        <RootBox>
            <RootContainer className={classes.root}>
                <CopyDialog handleClose={handleClose}/>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="management tabs">
                        <Tab label="現有停車場" {...a11yProps(0)} />
                        <Tab label="現有泊車內容" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <CarParkTable
                        onAddContent={addContentHandler} 
                        handleImageUpload={handleImageUpload} 
                        copyText={copyText} 
                        handleBoldBtn={handleBoldBtn}
                        handleItalicBtn={handleItalicBtn}
                        handleUnderlinedBtn={handleUnderlinedBtn} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ContentTable 
                        onAddContent={addCarParkHandler} 
                        handleImageUpload={handleImageUpload} 
                        copyText={copyText} 
                        handleBoldBtn={handleBoldBtn}
                        handleItalicBtn={handleItalicBtn}
                        handleUnderlinedBtn={handleUnderlinedBtn}/>
                </TabPanel>
            </RootContainer>
        </RootBox>
    )
}

const PREFIX = "contentMgn";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const RootContainer = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    }
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('/img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

export default EditManagementPage;