import { useState, useEffect, forwardRef, Fragment } from "react";
import { useParams } from 'react-router-dom';

import ParkBinList from "../components/carpark/ParkBinList";
import { CircularProgress, Container, Slide, Box, Divider, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { GoogleMap, useJsApiLoader, Marker, StreetViewPanorama, LoadScript, TrafficLayer, StreetViewService } from '@react-google-maps/api';
import "../index.css";
import SocialMediaBar from "../components/SocialMedia"
import CarParkImageList from "../components/carpark/CarParkImageList";
import { API_URL } from '../environment';


// proxy
// https://stackoverflow.com/questions/65427633/fetch-data-from-local-api

function ParkBinPage(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [parkBinDetail, setParkBinDetail] = useState([]);
    const [open, setOpen] = useState(false);
    const [parkBinCarparkDetail, setParkBinCarparkDetail] = useState([]);
    const { parkbinId, type } = useParams();

    const [loadedCarParks, setLoadedCarParks] = useState([]);

    useEffect(() => {
        const urls = [
            API_URL + `/api/parkbindetail?parkbinId=` + parkbinId,
            `https://api.data.gov.hk/v1/carpark-info-vacancy?data=info&carparkIds=tdcp2&lang=zh_TW`,
            `https://api.data.gov.hk/v1/carpark-info-vacancy?data=vacancy&carparkIds=tdcp2&lang=zh_TW`
        ]
        try {
            let parkbinDetail;
            let carparkIds;
            let carparkInfo, carparkVacancy;

            const getParkBinData = async () => {
                [parkbinDetail] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );

                for (var parkbinCarpark of parkbinDetail.parkBinCarParks) {
                    if (carparkIds) {
                        carparkIds += "," + parkbinCarpark.carparkId;
                    } else {
                        carparkIds = parkbinCarpark.carparkId;
                    }
                }

                const carparkInfo_Url = [
                    `https://api.data.gov.hk/v1/carpark-info-vacancy?data=info&carparkIds=`+ carparkIds +`&lang=zh_TW`,
                    `https://api.data.gov.hk/v1/carpark-info-vacancy?data=vacancy&carparkIds=`+ carparkIds +`&lang=zh_TW`
                ];

                [carparkInfo, carparkVacancy] = await Promise.all(
                    carparkInfo_Url.map((url) => fetch(url)
                                .then((res) => { return res.json(); })
                    )
                );

                setIsLoading(false);

                let infoArray = carparkInfo['results'];
                let vacancyArray = carparkVacancy['results'];

                var parkBinCarParks = parkbinDetail.parkBinCarParks;
                var carparkInfoVacancys = vacancyArray.map(x => Object.assign(x, infoArray.find(y => y.park_Id === x.park_Id)));
                var res = parkBinCarParks.map(x => Object.assign(x, carparkInfoVacancys.find(y => y.park_Id === x.carparkId)))
                
                parkbinDetail.parkBinCarParks = res;
                setParkBinDetail(parkbinDetail);
            }
            
            getParkBinData();

        } catch (error) {
            console.log(error);
        }

    }, []);

    if (isLoading) {
        return (
            <Root className={classes.loading}>
                <div className='loading-center'>
                    <div style={{ textAlign: 'center', marginBottom: '6rem' }}>載入中</div>
                    <div className='loading-center'>
                        <CircularProgress />
                    </div>
                </div>
            </Root>
        )
    }

    return  (
        <RootBox>
            <Root className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7}>
                        <MainContent parkBinDetail={parkBinDetail} />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <CarParkImageList.ParkingDiscount />
                        <CarParkImageList.ParkBin />
                    </Grid>
                </Grid>
            </Root>
        </RootBox>
    )
}

const MainContent = (props) => {
    return  (
        <>
            <Box sx={{p: 1}}>
                <Typography sx={{ typography: { sm: 'h4', xs: 'h4', md: 'h3'}}}>{props.parkBinDetail.title}</Typography>
            </Box>
            <SocialMediaBar url={window.location.href} title={props.parkBinDetail.title} />
            <img src={props.parkBinDetail.photoBanner} width={`100%`} />
            <Box sx={{p: 1}}>
                <Typography component={'span'} variant="body1"><div dangerouslySetInnerHTML={{ __html: props.parkBinDetail.content}}></div></Typography>
            </Box>
            <Divider variant="middle" />
            <ParkBinList carparks={props.parkBinDetail.parkBinCarParks} />
            <SocialMediaBar url={window.location.href} title={props.parkBinDetail.title} />
        </>
    ) 
}


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PREFIX = "carparkInfo";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        paddingBottom: '50px',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '100vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark' 
        ? '#1A2027' 
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));


export default ParkBinPage;
