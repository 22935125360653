import { Route, Routes, Navigate  } from "react-router-dom";
import { useContext, useMemo, useState } from "react";

// Pages
import AllMeetupsPage from "./pages/AllMeetups";
import HomePage from "./pages/Home";
import NewMeetupPage from "./pages/NewMeetup";
import FavoritesPage from "./pages/Favorites";
import CarParkInfoPage from "./pages/CarParkInfo";
import MapPage from "./pages/Map";
import ParkBinPage from "./pages/ParkBin";
import DrivingInfoPage from "./pages/DrivingInformation";
import SettingsPage from "./pages/Settings";
import PromotionPage from "./pages/Content"
import NewContentPage from "./pages/ContentManagement";
import EditManagementPage from "./pages/EditManagement";
import LoginPage from "./pages/Login";
import DistrictPage from "./pages/District";
import CategoryResultPage from "./pages/CategoryResult";
import CarParkDetailWithMapPage from "./pages/CarParkDetailWithMap";

// Components
import Layout from "./components/layout/Layout";
import Footer from './components/layout/Footer';
import Header from "./components/layout/Header";
import { Park } from "@mui/icons-material";

// Context
import AppContext from './store/app-context';
import { AuthProvider, useAuthState } from "./store/app-context";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
import './index.css'

function App() {
  const [open, setOpen] = useState(false);

  const { colorMode } = useContext(AppContext);
  const theme = useMemo(() => {
    return createTheme(getThemeTokens(colorMode), [colorMode])
  }, [colorMode]);

  const handleLoginBtnClick = () => {
    console.log('login btn click');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const PrivateRoute = ({children}) => {
    const auth = useAuthState();
    console.log('auth: ' + JSON.stringify(auth));
    return (auth.userDetails || auth.user) ? children : <Navigate to="/home" />;
  }

  return (
    // <ColorModeContext.Provider value={colorMode}>
    // <StyledEngineProvider injectFirst>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <div>
          <Header handleLoginBtnClick={handleLoginBtnClick} />
          <Routes>
            <Route path="/" element={<HomePage />} exact />
            <Route path="/home" element={<HomePage />} />
            <Route path="/new-meetup" element={<NewMeetupPage />} />
            <Route path="/favorites" element={<FavoritesPage />} />
            <Route path="/car-park-info" element={<CarParkInfoPage />} />
            <Route path="/car-park-detail/:Id" element={<CarParkDetailWithMapPage/>} />
            <Route path="/map" element={<MapPage />} />
            <Route path="/parkbin/:parkbinId/" element={<ParkBinPage />} />
            <Route path="/content/:type/:Id" element={<PromotionPage />} />
            <Route path="/drivingInfo" element={<DrivingInfoPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            {/* <Route path="/management/content/add" element={(!loggedIn ? <Navigate to ="/home" /> : <NewContentPage />)} /> */}
            <Route path="/management/content/add" 
                    element={
                      <PrivateRoute>
                        <NewContentPage />
                      </PrivateRoute>
                    } 
            />
            <Route path="/management/edit" 
                    element={
                      <PrivateRoute>
                        <EditManagementPage />
                      </PrivateRoute>
                    } 
            />
            <Route path="/district" element={<DistrictPage />} />
            <Route path="/category" element={<CategoryResultPage />} />
            <Route path="/category/:type" element={<CategoryResultPage />} />
          </Routes>
          <LoginPage open={open} handleClose={handleClose}/>
          <Footer />
        </div>
      </ThemeProvider>
    </AuthProvider>
    // </StyledEngineProvider>
    // </ColorModeContext.Provider>
  );
}

const getThemeTokens = (mode) => ({
  typography: {
    fontFamily: "'Noto Sans HK', sans-serif",
  },
  palette: {
    mode,
    ...(mode === "light"
      ? {
        // light mode: 
        background: {
          // default: "#f3c622",
        },
        primary: {
          main: "#f3c622", // yellow #fedb00
        },
      }
      : {
        // dark mode
        primary: {
          main: "#f3c622", // yellow
        },
        background: {
          default: "#121212",
          primary: "#1A2027"
        },
      }),
  },
})

export default App;
